@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.pagetop {
	display: flex;
	justify-content: flex-end;
	margin: 0 15px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 42px;
		background: #f3f3f3;
		border-radius: 5px 5px 0 0;
		color: $textColor;
		font-size: $xxs;
		@include font-bold;
	}
}


footer {
	padding: 35px 15px;
	background: #f3f3f3;
	
	.fnav01 {
		margin: 0 0 30px;
		
		&__content {
			display: flex;
			flex-wrap: wrap;
			
			&.column {
				margin-bottom: 35px;
				padding-bottom: 20px;
				border-bottom: 1px solid $borderColor;
			}
		}
		&__item {
			display: flex;
			align-items: center;
		}
		
		&__sns {
			display: flex;
			align-items: center;
			
			&__item {
				width: 30px;
				margin: 0 30px 0 0;
			}
		}
		
		
	}		
		
	.fnav02 {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 20px;
		font-size: $xxs;
		
		&__item {
			margin: 0 20px 10px 0;
			
			&__link {
				color: $textColor;
			}
		}
	}
	
	.copyright {
		font-size: $xxxs;
		text-align: center;
	}
	
}