@charset "utf-8";
/* ============================================================ */
/* あ、うちもノーリツキャンペーンLP */
/* /campaign/cp_auchimo.html */
/* ============================================================ */
#index_cp_auchimo {
  .contents {
    background-image: url(/campaign/img/cp_auchimo/bg_cp_auchimo.png);
    background-size: 50px 50px;
    padding: 0;
  }
  .cp_auchimo_main {
    width: 100%;
    margin: 0 auto;
	position: relative;
	z-index: 1;
    img {
      max-width: 100%;
    }
  }
  .cp_auchimo_main__02 {
	  position: relative;
	  .btn_click {
		position: absolute;
		bottom: 10%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0.7,0.7);
		  
	  }
	  
		@media screen and (max-width: 400px) {
			.btn_click {
				bottom: 6%;
				transform: translate(-50%,-50%) scale(0.5,0.5);
			}
		}

  }


  .cp_auchimo_scroll {
    width: auto;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 30px);
    z-index: 2;
	img {
      width: 60px;
    }
  }
	
	.cm {
		margin-bottom: 120px;
		padding: 160px 0 0;
		margin-top: -182px;
		z-index: 0;

		&::after {
		  position: absolute;
		  left: 0;
		  right: 0;
		  bottom: -70px;
		  z-index: 1;
		  content: '';
		  height: 70px;
		  background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_r_last.png) bottom center no-repeat;
		  background-size: 100% 100%;
		}

	}
	
  .yellow {
    background-color: #fff000;
    position: relative;
    margin: 50px 0 100px;
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      top: -70px;
      z-index: 1;
      content: '';
      height: 70px;
      background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_y_first.png) bottom center no-repeat;
      background-size: 100% 100%;
    }
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -70px;
      z-index: 1;
      content: '';
      height: 70px;
      background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_y_last.png) bottom center no-repeat;
      background-size: 100% 100%;
    }
	  
	.cp_auchimo_01 {
		&__thumb {
			.sound__box {
				position: relative;
				
				.sound__btn {
					cursor: pointer;
					position: relative;
					
					&::after {
						position: absolute;
						top: 52%;
						right: 5%;
						content: "";
						background: url(/campaign/img/cp_auchimo/pic_pickup_thumb_icon.png) no-repeat;
						background-size: contain;
						width: 64px;
						height: 47px;
					}
					
					@media screen and (min-width: 480px) and (max-width: 767px) {
					&::after {
						top: 52%;
						right: 5%;
					}
				}


				}
			}
		}
	}
  }
  .red {
    /*background: #ec1b24;*/
    background: #e4380d;
    position: relative;
  }
  .red--01 {
    top: -100px;
    padding: 130px 0 0;
    margin: 0;
  }
  .red--02 {
    position: relative;
    top: 0;
    padding: 50px 0;
    margin: 50px 0 0;
  }
  .red--03 {
    top: -100px;
    padding: 130px 0 0;
    margin: 0 0 -25px;
  }
  .red--01::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -40px;
    content: '';
    height: 40px;
    background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_tokuten_last.png) bottom center no-repeat;
    background-size: 100% 100%;
  }
  .red--02::after {
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
    content: '';
    height: 40px;
    background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_last.png) bottom center no-repeat;
    background-size: 100% 100%;
  }
  .red--03::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -40px;
    content: '';
    height: 40px;
    background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_tokuten_last.png) bottom center no-repeat;
    background-size: 100% 100%;
  }
  .pagetop {
    position: relative;
    z-index: 1;
  }
	
	#cp_auchimo_cp {
		margin-top: -60px;
	}
	
	.modalbg {
		background: #fff;
		filter: alpha(opacity=90);
		-moz-opacity: 0.90;
		opacity: 0.90;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		display: none;
	}

	.modalcontents {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20;
		width: 100%;
		padding: 0 2%;
		display: none;
		
		.modaltxt {
			font-size: 133.33%;
			color: #fff;
			text-align: center;
		}
		.modalmovie {
			margin-top: 20px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
			-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
			-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
			height: auto;
		}
		.modalclosebtn {
			position: absolute;
			top: -20px;
			right: 2%;
		}
		
		iframe {
			width: 100%;
			height: 100%;
		}
	}
	
	.red_text {
		color: #e8380d;
	}

	.gray_text {
		color: #222;
	}
	
	.campaign-pos {
		position: absolute;
		bottom: 10%;
		left: 50%;
		-webkit-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		-o-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
	}
}
