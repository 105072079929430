@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;

	@media screen and (min-width:  767.5px) and (max-width:1024px) {
		font-size: calc(100vw / 102.4);
	}
}

body {
	@include font-normal;
}

.wrap {
	word-break: break-all;
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
}

.sp-view {
	display: none !important;
}

a {
	color: $baseColor;
	@include transition;
	
	&:hover {
		@include opacity;
	}
}

a[href^="tel:"] {
	display: inline-block;
	pointer-events: none;
	cursor: default;
	color: $textColor;
	text-decoration: none;
	
	&:hover {
		opacity: 1;
	}
}

sup {
	font-size: $xxxs;
	vertical-align: super;
	transform: scale(0.8);
}
sub {
	font-size: $xxxs;
	vertical-align: baseline;
	transform: scale(0.8);
}

.link-gray {
	color: $textColor;
	
	&__opacity {
		@include transition;
	}
	&:hover {
		color: $baseColor;
		opacity: 1;
		
		.link-gray__opacity {
			@include opacity;
		}
	}
	& + .icon-keyboard_arrow_right {
		position: relative;
		top: 2px;
		margin: 0 0 0 2px;
		font-size: $l;
	}
	
}

.link-opacity {
	cursor: pointer;
	@include transition;
	
	&:hover {
		@include opacity;
	}
}

.icon-pdf {
	&::before {
		display: inline-block;
		content: '';
		width: 18px;
		height: 18px;
		background: url(/common/img/common/icon_pdf.png) center center no-repeat;
		background-size: 18px;
		line-height: 1;
		transform: translateY(3px);
	}
}

.icon-word { color: $word; }
.icon-excel { color: $excel; }
.icon-ppt { color: $ppt; }

.icon-word,
.icon-excel,
.icon-ppt {
	position: relative;
	top: 2px;
}


.accordion-hide {
	display: none;
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	background: #f3f3f3;
	font-size: $xxs;
	line-height: 1.4;
	
	.lnav {
		display: none;
	}
	
	&__inner {
		max-width: 1160px;
		margin: 0 auto;
		padding: 10px 20px;
	}
	&__body {
		position: relative;
		display: flex;
		overflow: hidden;

		&::after {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			content: '';
			width: 20px;
			background: linear-gradient(to right, rgba(#f3f3f3, 0) 0%, rgba(#f3f3f3, 1) 100%);
		}
	}
	&__item {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		&:not(:last-child) {
			&::after {
				margin: 0 5px;
				content: "\e315";
				color: $borderColor;
				font-size: $m;
				font-family: 'icomoon';
			}
		}
		
		&__link {
			color: $baseColor;
		}
	}
}


/* --------------------------------------------------- */
/* header-mypage */
/* --------------------------------------------------- */
.header-mypage {
	background: #e7e7e7;
	
	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__user {
		display: flex;
		align-items: center;
	}
	&__logout {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 30px;
		background: $white;
		border: 1px solid $borderColor;
		color: $textColor;
		font-size: $s;
		cursor: pointer;
		@include radius(4);
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}


/* --------------------------------------------------- */
/* modal */
/* --------------------------------------------------- */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 0 45px;
	background: rgba(#000, 0.4);
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
	
	&__body {
		position: relative;
		padding: 40px;
		background: $white;
		@include radius(8);
	}
	&__close-btn {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $white;
		text-align: center;
		transform: translate(20px, -20px);
		cursor: pointer;
		@include circle(50);
		
		& > * {
			display: flex;
			align-items: center;
			justify-content: center;
			background: $baseColor;
			color: $white;
			font-size: 3.0rem;
			@include circle(40);
		}
	}
	
	.slick-slider {
		position: static;
	}
	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	.slick-prev,
	.slick-next {
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 64px;
		background: rgba($baseColor, 0.5);
		color: $white;
		font-size: 2.6rem;
		@include transition;
		
		&:hover {
			background: rgba($baseColor, 0.5);
			color: $white;
			@include opacity;
		}
		
		&::before {
			display: none;
		}
	}
	.slick-prev { left: 0; }	
	.slick-next { right: 0; }	
	
	.slick-dots {
		position: static;
		margin-top: 25px;
		line-height: 0;
		
		li {
			width: 8px;
			height: 8px;
			margin: 0 4px;
			
			button {
				width: 8px;
				height: 8px;
				padding: 0;
				
				&::before {
					content: '';
					background: #e8e8e8;
					opacity: 1;
					@include circle(8);
				}
			}
			
			&.slick-active {
				button::before {
					background: #f39b86;
				}
			}
		}
	}
	
}


/* --------------------------------------------------- */
/* modal-video */
/* --------------------------------------------------- */
.modal-video {
	background: rgba(#000, 0.4) !important;
	
	.modal-video-body {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
	}
	.modal-video-inner {
		display: flex;
		padding: 60px 75px;
		height: auto;
		background: $white;
		@include radius(8);
	}
	.modal-video-close-btn {
		position: absolute;
		top: -80px;
		right: -95px;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $white;
		text-align: center;
		cursor: pointer;
		@include circle(50);
		
		&::before {
			position: static;
			content: '';
			margin: 0;
			background: $baseColor;
			@include circle(40);
		}
		&::after {
			position: absolute;
			content: "\e5cd";
			width: auto;
			height: auto;
			margin: 0;
			background: none;
			color: $white;
			font-family: 'icomoon';
			font-size: 3.0rem;
			@include centering-elements(true, true);
			@include radius(0);
		}
	}	
}

