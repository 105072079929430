@charset "utf-8";

.label {
  white-space: nowrap;
}

/* .バスルーム ローカルナビカレント
----------------------------------------------------------- */

#product_etc_ytvd-1201w-rc .lnav__level1__item--tv12 .lnav__level1__item__link,
#product_etc_oneseg .lnav__level1__item--oneseg .lnav__level1__item__link,
#product_etc_juke .lnav__level1__item--juke .lnav__level1__item__link,
#product_etc_jmoss .lnav__level1__item--jmoss .lnav__level1__item__link,
.product_etc_ozonsakkin .lnav__level1__item--ozonsakkin .lnav__level1__item__link {
	background: #f07355;
	border-color: #f07355;
	color: #fff;
	opacity: 1;

	&::before {
		opacity: 1;
		visibility: visible;
	}
}

/* .バスルーム ローカルナビカレント
----------------------------------------------------------- */

.product_bathroom_hiroy_d .lnav__level1__item--yupatio-d .lnav__level1__item__link,
.product_bathroom_hiroy_w .lnav__level1__item--yupatio-w .lnav__level1__item__link,
#product_bathroom_yupatio-kinou .lnav__level1__item--yupatio-kinou .lnav__level1__item__link,
#product_bathroom_aclead .lnav__level1__item--aclead .lnav__level1__item__link {
	background: #f07355;
	border-color: #f07355;
	color: #fff;
	opacity: 1;

	&::before {
		opacity: 1;
		visibility: visible;
	}
}

.product {

	/* .picture-tab
	----------------------------------------------------------- */
	.picture-tab {
		display: flex;
		flex-direction: column-reverse;
		transition: opacity 0.3s;

		&__image {
			display: none;
			transition: opacity 0.3s;

			&:nth-child(1) {
				display: block;
			}
		}

		> * {
				min-height: 0%;
		}

		&__tabs {
			margin-top: 20px;

			&__item {
				opacity: .5;
				cursor: pointer;
				transition: all 0.3s;

				&.current {
					opacity: 1;
				}
			}
		}
	}
	

	/* .picture-nav
	----------------------------------------------------------- */
	
	.picture-nav-main {
		&__image {
			display: none;
			transition: all 0.3s;
			
			&:nth-child(1) {
				display: block;
			}
		}
	}
	
	.picture-nav {
		&__navs__item {
			cursor: pointer;
			transition: all 0.3s;
			
			&:hover {
				opacity: .7;
			}
		}
		
	}

}