@charset "utf-8";

@import "_reset";
@import "_icon";
@import "_mixin";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_product";
	@import "pc/_product02";
	@import "pc/_showroom";
	@import "pc/_aftersupport";
	@import "pc/_special";
	@import "pc/_company";
	@import "pc/_info";
	@import "pc/_happypalette";
	@import "pc/_campaign";
	@import "pc/_ecorelakirei";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_product";
	@import "sp/_product02";
	@import "sp/_showroom";
	@import "sp/_aftersupport";
	@import "sp/_special";
	@import "sp/_company";
	@import "sp/_info";
	@import "sp/_happypalette";
	@import "sp/_campaign";
	@import "sp/_ecorelakirei";
}
@import "_utility";

@media print {
	@import "_print";
}