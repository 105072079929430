@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	max-width: 1160px;
	margin: 50px auto 80px;
	padding: 0 20px;
	
	&__inner {
		display: flex;
		justify-content: space-between;
	}
	.main {
		width: 71%;
	}
	.side {
		flex: 0 0 auto;
		width: 25%;
	}
}

.contents-lower {
	max-width: 1160px;
	margin: 0 auto 100px;
	padding: 0 20px;
}

.row {
	margin-bottom: 60px;
	
	&--narrow {
		max-width: 930px;
		margin-left: auto;
		margin-right: auto;
	}
	&--wide {
		max-width: 1160px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 20px;
	}
	&--bg-gray {
		background: #f3f3f3;
	}
	&--bg-red {
		background: #feead6;
	}
	&:last-child {
		margin-bottom: 0;
	}
}


.radius {
	@include radius(8);
}

.img-border {
	border: 1px solid $borderColor;
}

.ellipsis {
	position: relative;
	height: calc(1em * 1.8 * 2);
	text-align: justify;
	text-justify: inter-ideograph;
	word-break: break-all;
	overflow: hidden;
	
	&:before,
	&:after{
		position: absolute;
		background: $white;
	}
	&:before{
		top: calc(1em * 1.8 * (2 - 1));
		right: 0;
		content: '...';
		width: 1em;
		text-align: center;
	}
	&:after {
		content: '';
		height: 100%;
		width: 100%;
	}
}



/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 30px;
	font-size: 3.4rem;
	line-height: 1.4;
	@include font-bold;
	
	&__sub {
		display: block;
		margin: 5px 0 0;
		font-size: $m;
	}
} 

.ttl02 {
	margin: 0 0 30px;
	padding: 0 0 0 20px;
	border-left: 3px solid $baseColor;
	font-size: 3.0rem;
	line-height: 1.4;
	@include font-bold;
}

.ttl03 {
	margin: 0 0 25px;
	padding: 0 0 0 20px;
	border-left: 3px solid #f39b86;
	color: #333;
	font-size: $xxxl;
	line-height: 1.4;
	@include font-bold;
}

.ttl04 {
	margin: 0 0 20px;
	font-size: $l;
	line-height: 1.4;
	@include font-bold;
}

.ttl05 {
	color: $baseColor;
	font-size: 4.0rem;
	line-height: 1.4;
}

.ttl06 {
	font-size: 3.0rem;
	text-align: center;
	line-height: 1.4;
	@include font-bold;
}


/* --------------------------------------------------- */
/* .visual */
/* --------------------------------------------------- */
.visual {
	overflow: hidden;
	@include radius(8);
	
	.slick-prev,
	.slick-next {
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		background: rgba($white, 0.8);
		color: $baseColor;
		font-size: 2.6rem;
		@include transition;
		
		&:hover {
			background: rgba($white, 0.8);
			color: $baseColor;
			@include opacity;
		}
		
		&::before {
			display: none;
		}
	}
	.slick-prev { left: 0; }	
	.slick-next { right: 0; }	

	.slick-dots {
		bottom: 30px;
		line-height: 0;
		
		li {
			width: 8px;
			height: 8px;
			margin: 0 4px;
			
			button {
				width: 8px;
				height: 8px;
				padding: 0;
				
				&::before {
					content: '';
					background: $white;
					box-shadow: 0 0 15px rgba(#747f7e, 0.36);
					opacity: 0.5;
					@include circle(8);
				}
			}
			
			&.slick-active {
				button::before {
					opacity: 1;
					color: black;
				}
			}
		}
	}
	
	.slick-slide {
		overflow: hidden;
		@include radius(8);
	}
}


.visual02 {
	position: relative;
	
	&__lead {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: $white;
		font-size: 1.5rem;
		line-height: 2.2;
	}
}



/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 41px) / 2 );
	}
	.col2 {
		width: calc(100% - 20px);
	}
	
	&--wide {
		margin-left: -5px;
		margin-right: -5px;
		
		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 21px) / 2 );
		}
	}
}

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
	.col2 {
		width: calc((100% - 61px) / 3 * 2 + 20px);
	}
	.col3 {
		width: calc(100% - 20px);
	}
	
	&--wide {
		margin-left: -5px;
		margin-right: -5px;
		
		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 31px) / 3);
		}
	}
}

.column4 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 81px) / 4);
	}
	.col2 {
		width: calc((100% - 81px) / 4 * 2 + 20px);
	}
	.col3 {
		width: calc((100% - 81px) / 4 * 3 + 40px);
	}
	
	&--wide {
		margin-left: -5px;
		margin-right: -5px;
		
		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 41px) / 4);
		}
	}
}

.column5 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 101px) / 5);
	}
	.col2 {
		width: calc((100% - 101px) / 5 * 2 + 20px);
	}
	.col3 {
		width: calc((100% - 101px) / 5 * 3 + 40px);
	}
	&--wide {
		margin-left: -5px;
		margin-right: -5px;
		
		&> * {
			margin-left: 5px;
			margin-right: 5px;
			width: calc((100% - 51px) / 5);
		}
	}
}

.column6 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 121px) / 6);
	}
	.col2 {
		width: calc((100% - 121px) / 6 * 2 + 20px);
	}
	.col3 {
		width: calc((100% - 121px) / 6 * 3 + 40px);
	}
}

.column7 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 141px) / 7);
	}
	.col2 {
		width: calc((100% - 141px) / 7 * 2 + 20px);
	}
	.col3 {
		width: calc((100% - 141px) / 7 * 3 + 40px);
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 35px 25px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 25px 35px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.8em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 3px;
				height: 3px;
				border-radius: 50%;
			}
		}
	}
	&--gray {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}
	&--red {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 30px;
	background: $white;
	@include radius(8);
	
	&--gray {
		background: #f3f3f3;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--border-red {
		border: 1px solid $baseColor;
	}
	&--border-purple {
		border: 1px solid $purple;
	}
	&--orange {
		background: #feead6;
	}
	&--lightorange {
		background: #fcf1e6;
	}
	&--lightgray {
		background: #fbfbfb;
	}
	&--accordion {
		padding: 0;
		border: 1px solid $borderColor;
		
		&__ttl {
			padding: 10px 25px;
			background: rgba($baseColor, .1);
			font-size: $xxxl;
			line-height: 1.5;
			@include font-bold;
			
			.icon-add {
				font-size: $xxxl;
			}
			&.active {
				.icon-add::before { content: "\e15b"; }
			}
		}
		
		&__body {
			padding: 30px;
		}
	}
	
	&__band {
		margin: 0 -30px;
		padding: 10px 30px;
		max-width: none;
		
		&--gray {
			background: #f3f3f3;
		}
		&--red {
			background: $baseColor;
			color: $white;
		}
	}
}
.box-account {
	padding: 30px;
	border: 1px solid $borderColor;
	@include radius(8);
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
  border: 1px solid $borderColor;
  border-collapse: separate;
  border-spacing: 0;
	@include radius(8);
	
	th,
	td {
		padding: 20px 30px;
		background: $white;
		font-size: $s;
		text-align: left;
		vertical-align: top;
		line-height: 1.6;
	}
	th {
		background: #f3f3f3;
		@include font-bold;
	}
	th,td {
		+th,+td {
		  border-left: 1px solid $borderColor;
		}
	}
	tr+tr {
		th,td{
		border-top: 1px solid $borderColor;
	  	}
	}

	&--thin {
		th,
		td {
			padding: 15px;
		}
	}
	&--thin2 {
		th,
		td {
			padding: 10px;
			font-size: $xxs;
		}
	}
	
	.bg-white {
		background: $white;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.bg-darkgray {
		background: #ebebeb;
	}
	.bg-red {
		background: #feead6;
	}
	
	tr > *:not(:first-child),
	.bdr-left {
		border-left: 1px solid $borderColor;
	}

	.bdr-radius {
		border-bottom-left-radius: 8px !important;
	}

	.bdr-radius-none {
		border-bottom-left-radius: 0 !important;
	}

	thead {
		tr:first-child {
			& > *:first-child {
				border-top-left-radius: 8px;
			}
			& > *:last-child {
				border-top-right-radius: 8px;
			}
		}
	}
	tbody {
		tr:last-child {
			& > * {
				border-bottom: none;

				&:first-child {
					border-bottom-left-radius: 8px;
				}
				&:last-child {
					border-bottom-right-radius: 8px;
				}
			}
		}
		
		&:first-child {
			tr:first-child {
				& > *:first-child {
					border-top-left-radius: 8px;
				}
				& > *:last-child {
					border-top-right-radius: 8px;
				}
			}
		}
	}
}



/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 360px;
	min-height: 65px;
	margin: 0 10px;
	padding: 20px 30px;
	background: $white;
	border: 1px solid $borderColor;
	color: $textColor;
	font-size: $m;
	text-align: center;
	text-decoration: none;
	line-height: 1.5;
	cursor: pointer;
	@include transition;
	@include radius(8);

	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	[class*="icon-angle-"],
	[class*="icon-keyboard_arrow_"] {
		font-size: $xl;
	}
	
	&:hover {
		background: $baseColor;
		border-color: $baseColor;
		color: $white;
		opacity: 1;
		
		.u-font-red,
		.u-font-lightgray {
			color: $white !important;
		}
	}
	&:disabled {
		opacity: 0.5;
		cursor: auto;
		
		&:hover {
			background: $white;
			border-color: $borderColor;
			color: $textColor;
			
			.u-font-red { color: $baseColor !important; }
			.u-font-lightgray { color: #797c7c !important; }
		}
	}
	&--lg {
		max-width: 550px;
	}
	&--md {
		max-width: 275px;
	}
	&--sm {
		max-width: 200px;
	}
	&--xs {
		max-width: 200px;
		min-height: 0;
		height: 35px;
		font-size: $xxs;
		@include radius(4);
	}
	&--orange {
		background: #feead6;
		
		&:hover {
			background: #feead6;
			border-color: $borderColor;
			color: $textColor;
			@include opacity;
		}
	}
	&--hover-opacity {
		&:hover {
			background: $white;
			border-color: $borderColor;
			color: $textColor;
			@include opacity;
			
			.u-font-red { color: $baseColor !important; }
			.u-font-lightgray { color: #797c7c !important; }
		}
	}
	&.accordion {
		&.active {
			.icon-add::before {
				content: "\e15b";
			}
		}
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

.btn-block {
	display: flex;
	justify-content: center;
	margin: 0 -10px;
	
	&--column1 {
		flex-direction: column;
		align-items: center;
		
		.btn:not(:last-child) {
			margin-bottom: 30px;
		}
	}
}


/* --------------------------------------------------- */
/* related-link */
/* --------------------------------------------------- */
.related-link {
	&__item {
		display: flex;
		align-items: center;
		height: 158px;
		padding: 0 1.8em 0 2.85em;
		border: 1px solid $borderColor;
		line-height: 1.6;
		@include font-bold;
		@include radius(8);
		
		&__icon {
			flex: 0 0 auto;
			margin: 0 25px 0 0;
			color: $baseColor;
			font-size: 8.0rem;
		}
		&__text {
			flex: 1 1 auto;
			margin: 0 5px 0 0;
			color: $textColor;
			font-size: $l;
		}
		[class*="icon-angle-"] {
			font-size: $xl;
		}
		
		&--company-product { background-image: url(/common/img/common/bg_related_link_company_product.png); }
		&--company-about { background-image: url(/common/img/common/bg_related_link_company_about.png); }
		&--company-ir { background-image: url(/common/img/common/bg_related_link_company_ir.png); }
		&--company-csr { background: url(/common/img/common/bg_related_link_company_csr.png); }
		&--company-news { background-image: url(/common/img/common/bg_related_link_company_news.png); }
	}
	[class*="related-link__item--company-"] {
		padding-left: 145px;
		background-position: 50% left;
		background-repeat: no-repeat;
		background-size: auto 100%;
	}
}


/* --------------------------------------------------- */
/* menu-btn */
/* --------------------------------------------------- */
.menu-btn {
	position: relative;
	display: block;
	width: 100%;
	height: 150px;
	background: $white;
	border: 1px solid $borderColor;
	color: $textColor;
	font-size: $m;
	line-height: 1.4;
	cursor: pointer;
	@include transition;
	@include radius(8);
	
	.icon-fix-left,
	.icon-fix-right {
		font-size: $l;
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 15px; }
	.icon-fix-right { right: 15px; }
	
	[class*="icon-keyboard_arrow_"] {
		font-size: $xl;
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 150px;
	}
	&__icon {
		width: 60px;
		margin: 0 0 10px;
	}
	&--sm {
		display: flex;
		align-items: center;
		height: 80px;
		padding: 0 0 0 20px;
		text-align: left;
		
		.menu-btn__icon {
			flex: 0 0 auto;
			width: auto;
			margin: 0 15px 0 0;
			color: $baseColor;
			font-size: 3.0rem;
		}
	}
	
	&--lg {
		height: 210px;
		font-size: $l;
		@include font-bold;
		
		.menu-btn__body {
			height: 210px;
		}
		.menu-btn__icon {
			color: $baseColor;
			font-size: 6.0rem;
		}
	}
	
}


/* --------------------------------------------------- */
/* memu-box */
/* --------------------------------------------------- */
.memu-box {
	display: flex;
	padding: 25px 15px;
	border: 1px solid $borderColor;
	@include radius(8);
	
	& > * {
		width: calc((100% - 40px) / 2);
		margin: 0 10px;
	}
	&__ttl {
		margin: 0 0 10px;
	}
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			text-decoration: none;
			@include radius(3);
			
			&.current,
			&:hover {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: -10px;
	padding: 3px 8px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	line-height: 1.2;
	@include radius(4);
	@include centering-elements(false, true);
}

.error {
	margin: 5px 0 0;
	color: $baseColor;
}

.hankaku {
	ime-mode: disabled;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="password"] {
	appearance: none;
	height: 35px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
	@include font-form;
}
input::-webkit-input-placeholder { color: $borderColor; }
input:-moz-placeholder { color: $borderColor; }
input::-moz-placeholder { color: $borderColor; }
input:-ms-input-placeholder { color: $borderColor; }

input:focus::-webkit-input-placeholder { color: transparent; }
input:focus:-moz-placeholder { color: transparent; }
input:focus::-moz-placeholder { color: transparent; }
input:focus::-ms-input-placeholder { color: transparent; }

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
	@include font-form;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 28px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: $white;
			border: 1px solid #b0b0b0;
			@include circle(16);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::before {
			border-color: $baseColor;
		}
		&::after {
			opacity: 1;
		}
	}
}


/* radio-btn
----------------------------------------------------------- */
.radio-btn {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	
	input[type="radio"] + .radio-btn__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 210px;
		padding: 10px 15px;
		background: $white;
		border: 1px solid $borderColor;
		font-size: $xxxl;
		text-align: center;
		@include radius(8);
		@include font-bold;
		
		.radio-btn__icon {
			margin: 0 0 10px;
			color: $baseColor;
			font-size: 7.0rem;
			text-align: center;
			line-height: 1;
		}
	}
	input[type="radio"] + .radio-btn__body:hover {
		border: 3px solid rgba($baseColor, .4);
	}
	input[type="radio"]:checked + .radio-btn__body {
		border: 3px solid $baseColor;
	}
	
	input[type="radio"]:disabled + .radio-btn__body {
		background: #f0f0f0;
		cursor: default;
		
		.radio-btn__icon,
		.radio-btn__text {
			opacity: 0.4;
		}
	}
	input[type="radio"]:disabled + .radio-btn__body:hover {
		border: 1px solid $borderColor;
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 30px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 16px;
			height: 16px;
			background: $white;
			border: 1px solid #b0b0b0;
		}
		&::after {
			left: 2px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::before {
			border-color: $baseColor;
		}
		&::after {
			opacity: 1;
		}
	}
	&--border-red {
		input[type="checkbox"] + span {
			&::before {
				border: 2px solid $baseColor;
				@include radius(4);
			}
		}
	}
}


/* checkbox-btn
----------------------------------------------------------- */
.checkbox-btn {
	position: relative;
	
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + .checkbox-btn__body  {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 70px;
		padding: 0 0 0 50px;
		background: $white;
		color: $textColor;
		font-size: $l;
		cursor: pointer;
		@include radius(8);
		@include font-bold;
		@include transition;
		
		&::before,
		&::after {
			position: absolute;
			content: '';
		}
		&::before {
			top: 1px;
			bottom: 1px;
			left: 1px;
			width: 50px;
			border-radius: 8px 0 0 8px;
			background: $orange;
		}
		&::after {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 1px solid $borderColor;
			@include radius(8);
		}
		&:hover {
			&::after {
				border: 2px solid #f5a386;
			}
		}
		
		.checkbox-btn__check {
			&::after {
				left: 13px;
				content: "\e835";
				color: $white;
				font-family: 'icomoon';
				font-size: 2.8rem;
				@include centering-elements(false, true);
			}
		}
	}
	input[type="checkbox"]:checked + .checkbox-btn__body {
		&::after {
			border: 2px solid $baseColor;
		}
		
		.checkbox-btn__check {
			&::after {
				content: "\e834";
			}
		}
	}
	
	
	input[type="checkbox"]:disabled {
		cursor: default;
	}
	input[type="checkbox"]:disabled + .checkbox-btn__body {
		cursor: default;
		padding: 0;
		
		&::before {
			display: none;
		}
		&:hover {
			&::after {
				border: 1px solid $borderColor;
			}
		}
		
		.checkbox-btn__check {
			&::after {
				display: none;
			}
		}
	}
	
}


/* selectbox
----------------------------------------------------------- */
.selectbox {
	position: relative;
	height: 35px;
	background: $white;
	border: 1px solid $borderColor;
	cursor: pointer;
	
	&::after {
		right: 0;
		width: 35px;
		content: "\e313";
		font-family: 'icomoon';
		color: $baseColor;
		font-size: 2.6rem;
		text-align: center;
		pointer-events: none;
		@include centering-elements(false, true);
	}
	
	select {
		width: 100%;
		height: 33px;
		padding: 0 10px;
		cursor: pointer;
		appearance: none;
		
		&:disabled {
			color: $textColor;
		}
	}
	select::-ms-expand {
		display: none;
	}	
	option {
		color: $textColor;
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 17px;
	border: 1px solid #d8d8d8;
	font-size: $xxs;
	text-align: center;
	line-height: 25px;
	
	&--important {
		background: $baseColor;
		border-color: $baseColor;
		color: $white;
	}
	&--radius {
		display: inline-flex;
		width: auto;
		min-width: 90px;
		height: 25px;
		padding: 0 15px;
		border: none;
		@include radius(8);
		@include font-bold;
	}
	&--radius-lg {
		display: inline-flex;
		width: auto;
		height: 32px;
		padding: 0 15px;
		font-size: $s;
		border: none;
		@include radius(50);
	}
	&--gray {
		background: #f3f3f3;
	}
	&--border-red {
		border: 1px solid $baseColor;
		color: $baseColor;
	}
	&--red {
		background: $baseColor;
		color: $white;
	}
	&--red-lighten {
		background: #feead6;
	}
	&--red-lighten02 {
		background: #ef7456;
		color: $white;
	}
	&--blue-lighten {
		background: #7aacd8;
		color: $white;
	}
}

.label-feature {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	border-radius: 100px;
	text-align: center;
	line-height: 1.4;
	
	&--on {
		background: #feead6;
	}
	&--off {
		background: #ebebeb;
		color: rgba($textColor, 0.3);
	}
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	border-top: 1px solid $borderColor;
	
	&__item {
		display: flex;
		align-items: center;
		padding: 15px 3.5%;
		border-bottom: 1px solid $borderColor;
		
		&__date {
			flex: 0 0 auto;
			margin: 0 20px 0 0;
			font-size: $xxs;
		}
		&__category {
			flex: 0 0 auto;
			margin: 0 20px 0 0;
		}
		&__text {
			flex: 1 1 auto;
			font-size: $m;
		}
		&__link {
			color: $textColor;
			
			&:hover {
				opacity: 1;
				color: $baseColor;
			}
		}
		
		.icon-keyboard_arrow_right {
			flex: 0 0 auto;
			margin: 0 0 0 10px;
			color: $baseColor;
			font-size: $xl;
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	
	p {
		margin-bottom: 0;
	}
}



/* --------------------------------------------------- */
/* link-movie */
/* --------------------------------------------------- */
.link-movie {
	position: relative;
	display: block;
	
	&__icon {
		color: $white;
		font-size: 4.8rem;
		text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
		@include centering-elements(true, true);
		
		&--lg {
			font-size: 6.0rem;
		}
	}
}



/* --------------------------------------------------- */
/* contact-center */
/* --------------------------------------------------- */
.contact-center {
	display: flex;
	justify-content: center;
	
	& > *:not(.box__band) {
		max-width: 456px;
		width: 100%;
		margin: 0 10px;
	}
	
	&__phone {
		text-align: center;
		
		&__ttl {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $l;
			@include font-bold;
			
			.icon-settings_phone {
				margin: 0 5px 0 0;
				color: $baseColor;
				font-size: 2.6rem;
			}
		}
		&__number {
			margin: 0 0 5px;
			color: $baseColor !important;
			font-size: 4.0rem;
			line-height: 1.2;
			@include font-bold;
			
			&--small {
				font-size: 3.4rem;
			}
		}
		&__charge {
			max-width: 350px;
			margin: 0 auto 10px;
			border: 1px solid $textColor;
			font-size: $xxs;
			@include radius(50);
		}
		&__mobile {
			font-size: $xxs;
			line-height: 1.4;
			
			&__number {
				margin: 0 0 0 5px;
				font-size: $xxxl;
				@include font-bold;
			}
		}
	}
	&__reception {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


/* --------------------------------------------------- */
/* anchor-link */
/* --------------------------------------------------- */
.anchor-link {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	
	&__item {
		border-right: 1px solid $borderColor;
		
		&:first-child {
			border-left: 1px solid $borderColor;
		}
		
		&__link {
			display: flex;
			align-items: center;
			margin: 0 1.4em;
			color: $textColor;
			
			.icon-keyboard_arrow_down {
				font-size: $xl;
			}
			&:hover {
				color: $baseColor;
				opacity: 1;
			}
		}
	}
	
	&--column2 {
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: flex-start;
		
		.anchor-link__item {
			display: flex;
			width: 50%;
			margin: 10px 0 0;
			
			&:nth-child(2n+1) {
				border-left: 1px solid $borderColor;
			}
			&:nth-child(-n+2) {
				margin-top: 0;
			}
		}
		.anchor-link__item__link {
			width: 100%;
		}
		.anchor-link__item__link__text {
			flex: 1 1 auto;
			text-align: center;
		}
	}
}


/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.lnav {
	display: flex;
	margin: 0 -0.57em 40px;
	line-height: 1.4;
	
	&__ttl {
		flex: 1 1 auto;
		display: flex;
		margin: 0 0.57em;
		
		&__link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			background: $baseColor;
			color: $white;
			text-align: center;
			@include radius(6);
			
			&::before {
				z-index: 5;
				bottom: -8px;
				content: '';
				border-style: solid;
				border-width: 8px 8px 0;
				border-color: $baseColor transparent transparent;
				opacity: 0;
				visibility: hidden;
				@include transition;
				@include centering-elements(true, false);
			}
			&--current {
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	
	&__level1 {
		position: relative;
		flex: 5 1 auto;
		display: flex;
		
		&__item {
			flex: 1 1 auto;
			position: relative;
			margin: 0 0.57em;
			
			&__link {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 30px; // for IE11
				min-height: 50px;
				background: $white;
				border: 1px solid $borderColor;
				color: $textColor;
				text-align: center;
				@include radius(6);
				
				&::before {
					bottom: -8px;
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 8px 0;
					border-color: #f07355 transparent transparent;
					opacity: 0;
					visibility: hidden;
					@include transition;
					@include centering-elements(true, false);
				}
			}
			
			&:hover {
				.lnav__level1__item__link {
					background: #f07355;
					border-color: #f07355;
					color: $white;
					opacity: 1;
					
					&::before {
						opacity: 1;
						visibility: visible;
					}
				}
				
				.lnav__level2 {
					opacity: 1;
					visibility: visible;
				}
			}

			&--float {
				position: static;
			}
		}
	}
	
	&__level2 {
		position: absolute;
		top: 50px;
		left: 0;
		z-index: 5;
		width: 265px;
		padding: 10px 0 0;
		opacity: 0;
		visibility: hidden;
		
		.lnav__level1__item--float & {
			left: auto;
			right: 0.57em;
		}

		&__item {
			border: 1px solid $borderColor;
			border-bottom: none;
			
			&:first-child,
			&:first-child > * {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
			&:last-child,
			&:last-child > * {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
			&:last-child {
				border-bottom: 1px solid $borderColor;
			}
			&__link {
				display: flex;
				align-items: center;
				padding: 15px;
				background: #feead6;
				color: $textColor;
				
				.icon-keyboard_arrow_right {
					margin-left: auto;
					color: $baseColor;
					font-size: $xxl;
				}
				&:hover {
					color: $baseColor;
					opacity: 1;
				}
			}
		}
	}
	
}

.product_kyutoki_gus .lnav__level1__item--gus,
#product_kyutoki_hybrid_mb .lnav__level1__item--gus,
.product_kyutoki_oil .lnav__level1__item--oil,
.product_kyutoki_hybrid:not(#product_kyutoki_hybrid_mb) .lnav__level1__item--hybrid,
.product_kyutoki_gyoumuyou .lnav__level1__item--pro,
.product_onsui_danbou_mist_dryhot .lnav__level1__item--mist,
.product_onsui_danbou_yukadan .lnav__level1__item--yukadan,
.product_onsui_danbou_livung .lnav__level1__item--livung,
#product_onsui_danbou_eco_j .lnav__level1__item--eco-j,
.product_kitchen_recipia_plus .lnav__level1__item--recipia_plus,
#product_kitchen_recipia_plus .lnav__level1__item--recipia_plus,
.product_kitchen01_builtin .lnav__level1__item--builtin,
.product_kitchen01_table .lnav__level1__item--table,
.product_kitchen01_rangehood .lnav__level1__item--rangehood,
.product_kitchen01_oven .lnav__level1__item--oven,
#product_kitchen01_takujyouoven .lnav__level1__item--takujyouoven,
#product_kitchen01_cartridge .lnav__level1__item--cartridge,
.product_bathroom_yupatio .lnav__level1__item--yupatio,
.product_bathroom_yupatioms .lnav__level1__item--yupatio-ms,
#product_bathroom_kinou .lnav__level1__item--yupatio-kinou,
.product_bathroom_acleadseries .lnav__level1__item--aclead,
.product_kitchen_jtm .lnav__level1__item--jtm,
#product_kitchen_recipia_plus_cyouri .lnav__level1__item--jtm,
.product_sanitary_cubo .lnav__level1__item--cubo,
.product_sanitary_shampine_new .lnav__level1__item--shampine,
.product_sanitary_sophinia .lnav__level1__item--sophinia,
.product_sanitary_sophiniacrie .lnav__level1__item--sophiniacrie,
#product_gas_fanheater_deluxe .lnav__level1__item--deluxe,
#product_gas_fanheater_standard .lnav__level1__item--standard,
#product_gas_fanheater_warm_life .lnav__level1__item--warm,
#product_taiyo_vf_series .lnav__level1__item--vf,
#product_taiyo_xf_series .lnav__level1__item--xf,
#product_taiyo_uf_series .lnav__level1__item--other,
#product_taiyo_sjq_series .lnav__level1__item--other,
#product_taiyo_sj_series .lnav__level1__item--other,
#product_taiyo_skb_series .lnav__level1__item--other,
#product_taiyo_skypia_pro .lnav__level1__item--other,
#product_taiyo_faq .lnav__level1__item--faq,
#product_taiyo_caution .lnav__level1__item--caution {
	.lnav__level1__item__link {
		background: #f07355;
		border-color: #f07355;
		color: $white;
		opacity: 1;
		
		&::before {
			opacity: 1;
			visibility: visible;
		}
	}
}


/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	border: 1px solid $borderColor;
	overflow: hidden;
	@include radius(8);
	
	&__ttl {
		
		&__link {
			display: flex;
			align-items: center;
			min-height: 60px;
			padding: 0.6em 0.83em;
			color: $textColor;
			font-size: $l;
			line-height: 1.4;
			
			&__text {
				flex: 1 1 auto;
			}
			&:hover {
				background: #feead6;
				color: $baseColor;
				opacity: 1;
			}
		}
	}
	&__level1,
	&__level2,
	&__level3,
	&__level4 {
		
		&__item {
			display: flex;
			flex-direction: column;
			border-top: 1px solid $borderColor;
			
			&__link {
				display: flex;
				align-items: center;
				width: 100%;
				min-height: 50px;
				padding: 10px 1.1em;
				color: $textColor;

				&__text {
					flex: 1 1 auto;
				}
				.icon-keyboard_arrow_right {
					color: $baseColor;
					font-size: $xxl;
				}
				&:hover {
					color: $baseColor;
					opacity: 1;
				}
				&--current {
					background: #feead6;
				}
			}
		}
	}
	&__level2 {
		display: none;
		
		&__item {
			&__link {
				padding-left: 2.15em;
			}
		}
		
		.snav__level1__item__link--current + & {
			display: block;
		}
	}
	&__level3 {
		display: none;
		
		&__item {
			&__link {
				padding-left: 4.3em;
			}
		}
		
		.snav__level1__item__link--current + & {
			display: block;
		}
	}
	&__level4 {
		display: none;
		
		&__item {
			&__link {
				padding-left: 6.45em;
			}
		}
		
		.snav__level1__item__link--current + & {
			display: block;
		}
	}
}


/* カレント表示 */

.info_safety .snav__level1__item--safety,
.info_houtei .snav__level1__item--houtei,
.info_group .snav__level1__item--group,
.company_about_outline .snav__level1__item--outline,
.company_about_sports_athletic .snav__level1__item--sports,
.company_about_sports_fencing .snav__level1__item--sports,
.company_ir_individual .snav__level1__item--individual,
.company_ir_vision .snav__level1__item--vision,
.company_ir_ir_library .snav__level1__item--library,
.company_ir_stock .snav__level1__item--stock,
.company_ir_ir_event .snav__level1__item--event,
.company_about_outline .snav__level1__item--outline,
.company_about_sports .snav__level1__item--sports,
.company_csr_csrabout .snav__level1__item--csrabout,
.company_csr_topics .snav__level1__item--topics,
.company_csr_quality .snav__level1__item--quality,
.company_csr_kankyou .snav__level1__item--kankyou,
.company_csr_social .snav__level1__item--social,
.company_csr_governance .snav__level1__item--governance,
.company_csr_engagement .snav__level1__item--engagement,
.company_csr_csr_library .snav__level1__item--library,
.aftersupport_hosyu .snav__level1__item--hosyu,
.aftersupport_maintenance .snav__level1__item--maintenance,
.aftersupport_repair .snav__level1__item--repair {
	.snav__level2 {
		display: block;
	}
}
.company_about_outline_office .snav__level1__item--outline,
.info_houtei_houtei_tenken .snav__level2__item--houtei_tenken,
.info_houtei_anshin .snav__level2__item--anshin,
.company_about_sports_athletic .snav__level2__item--athletic,
.company_about_sports_fencing .snav__level2__item--fencing,
.company_csr_kankyou_seihin .snav__level2__item--seihin,
.aftersupport_hosyu_anshins .snav__level2__item--anshins {
	.snav__level3 {
		display: block;
	}
}
.info_houtei .snav__level3,
.company_about_sports_athletic_schedule .snav__level3__item--schedule,
.company_about_sports_fencing_schedule .snav__level3__item--schedule {
	.snav__level4 {
		display: block;
	}
}
.info_safety_gus .snav__level2__item--gus,
.info_houtei_toku_a .snav__level2__item--search,
.info_houtei_toku_b .snav__level2__item--search,
.info_houtei_toku_c .snav__level2__item--search,
.info_houtei_toku_d .snav__level2__item--search,
.info_houtei_kiken .snav__level2__item--search,
.info_houtei_ippan_f .snav__level2__item--search,
.info_houtei_ippan_g .snav__level2__item--search,
.info_houtei_ippan_h .snav__level2__item--search,
.info_houtei_gyoumu .snav__level2__item--search,
.info_houtei_error .snav__level2__item--search,
#company_about_sports_athletic_index .snav__level2__item--athletic,
#company_about_sports_fencing_index .snav__level2__item--fencing {
	.snav__level2__item__link {
		background: #feead6;
	}
}
.company_about_sports_athletic_photolibrary .snav__level2__item--athletic .snav__level3__item--photolibrary,
.company_about_sports_fencing_photolibrary .snav__level2__item--fencing .snav__level3__item--photolibrary,
#company_csr_kankyou_seihin_forestoffsset .snav__level2__item--seihin .snav__level3__item--forestoffsset,
#company_csr_kankyou_seihin_forestoffset2017_01 .snav__level2__item--seihin .snav__level3__item--forestoffsset,
#company_csr_kankyou_seihin_forestoffset2017_02 .snav__level2__item--seihin .snav__level3__item--forestoffsset,
#company_csr_kankyou_seihin_forestoffset2017_03 .snav__level2__item--seihin .snav__level3__item--forestoffsset,
#company_csr_kankyou_seihin_forestoffset2017_04 .snav__level2__item--seihin .snav__level3__item--forestoffsset {
	.snav__level3__item__link {
		background: #feead6;
	}
}




/* --------------------------------------------------- */
/* progress */
/* --------------------------------------------------- */
.progress {
	display: flex;
	
	&__item {
		flex: 1 1 auto;
		position: relative;
		display: flex;
		padding: 0 22px 0 0;
		
		&__text {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			min-height: 50px;
			padding: 5px 0;
			background: #f3f3f3;
			border-radius: 8px 0 0 8px;
			line-height: 1.4;
		}
		&::after {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 2px;
			content: '';
			width: 22px;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-image: url(/common/img/common/bg_progress_arrow_gray.svg);
			background-size: 22px 100%;
		}
		
		&:last-child {
			padding-right: 0;
			
			&:after {
				display: none;
			}
			.progress__item__text {
				@include radius(8);
			}
		}
		
		&--current {
			.progress__item__text {
				background: $baseColor;
				color: $white;
			}
			&::after {
				background-image: url(/common/img/common/bg_progress_arrow_red.svg);
			}
		}
	}
}


/* --------------------------------------------------- */
/* progress-step */
/* --------------------------------------------------- */
.progress-step {
	
	&__item {
		display: flex;
		min-height: 110px;
		margin-bottom: 0;
		
		&__body {
			display: flex;
			width: calc(100% - 42px);
			background: $white;
			border: 2px solid $baseColor;
			border-right: none;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		&::after {
			content: '';
			width: 42px;
			background: url(/common/img/common/bg_progress_step_arrow.svg) 0 0 no-repeat;
			background-size: 100% auto;
		}
		&__step {
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 42px;
			background: $baseColor;
			color: $white;
			text-align: center;
			line-height: 1.2;
			@include font-bold;
			
			&__text {
				font-size: $xxxs;
				transform: scale(0.8);
				transform-origin: center center;
			}
			&__number {
				font-size: $l;
			}
		}
		&__text {
			flex: 1 1 auto;
			display: flex;
			justify-content: center;
			padding: 0 0 0 1em;
			align-self: center;
		}
	}
}


/* --------------------------------------------------- */
/* agreement */
/* --------------------------------------------------- */
.agreement {
	padding: 30px;
	border: 1px solid $borderColor;
	overflow-y: auto;
}


/* --------------------------------------------------- */
/* panel-link */
/* --------------------------------------------------- */
.panel-link {
	position: relative;
	display: block;
	
	&__ttl {
		display: flex;
		align-items: center;
		left: 30px;
		color: $white;
		font-size: $xxxl;
		text-shadow: 0 0 15px rgba(#000, 0.3);
		line-height: 1.4;
		@include centering-elements(false, true);
		
		.icon-circle-arrow-right {
			font-size: $l;
		}
	}
}


/* --------------------------------------------------- */
/* lineup */
/* --------------------------------------------------- */
.lineup {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
	
	&__thumb {
		order: 1;
		width: 52.3%;
		padding: 35px 0;
		border: 1px solid $borderColor;
		border-right: none;
		border-radius: 8px 0 0 8px;
		@include transition;
	}
	
	&__ttl {
		order: 3;
		width: 100%;
		margin: 20px 0 0;
	}
	
	&__text {
		order: 2;
		width: 47.6%;
		padding: 25px;
		border: 1px solid $borderColor;
		border-radius: 0 8px 8px 0;
		@include transition;
	}
	
	&.link-gray {
		&:hover {
			.lineup__text {
				color: $textColor;
			}
			.lineup__thumb,
			.lineup__text {
				@include opacity;
			}
		}
	}
}


.lineup02 {
	display: flex;
	margin: 0 0 20px;
	border: 1px solid $borderColor;
	@include radius(8);
	
	&__thumb {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 52.7%;
		padding: 30px;
	}
	&__legend {
		display: flex;
		flex-wrap: wrap;
		width: 47.3%;
		
		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 50%;
			border-left: 1px solid $borderColor;
			color: #d2d2d2;
			@include font-bold;
			
			&:nth-child(n+3) {
				border-top: 1px solid $borderColor;
			}
			&__icon {
				margin: 0 0 10px;
				font-size: 6.0rem;
			}
			
			&--support {
				color: $textColor;
				
				.lineup02__legend__item__icon { color: $orange; }
			}
		}
	}
}


.lineup03 {
	margin-bottom: 20px;
	border: 1px solid $borderColor;
	word-break: keep-all;
	@include radius(8);
	
	.tablet & {
		word-break: normal;
	}
	
	&__ttl {
		padding: 10px 15px;
		border-bottom: 1px solid $borderColor;
		font-size: $m;
	}
	&__body {
		display: flex;
		padding: 30px 0;
		
		& > * {
			width: calc((100% - 60px) / 2);
			margin: 0 15px;
		}
	}
	&__thumb {
		text-align: center;
	}
}


/* --------------------------------------------------- */
/* legend */
/* --------------------------------------------------- */
.legend {
	display: flex;
	
	&__item {
		display: flex;
		align-items: center;
		margin: 0 40px 0 0;
		
		&__icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 38px;
			height: 38px;
			margin: 0 15px 0 0;
			border: 1px solid $orange;
			color: $orange;
			font-size: $xxxl;
			@include radius(8);
		}
	}
}


/* --------------------------------------------------- */
/* product-info-icon */
/* --------------------------------------------------- */
.product-info-icon {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 25px;
	
	&__content1 {
		display: flex;
		flex-wrap: wrap;
		width: 17.2%;
		
		&__item {
			width: calc((100% - 10px) / 2);
			margin: 0 0 0 9px;
			
			&:nth-child(2n+1) {
				margin-left: 0;
			}
		}
	}
	&__content2 {
		display: flex;
		flex-wrap: wrap;
		width: 80%;
		
		&__item {
			width: calc((100% - 27px) / 4);
			margin: 9px 0 0 9px;
			
			&:nth-child(4n+1) {
				margin-left: 0;
			}
			&:nth-child(-n+4) {
				margin-top: 0;
			}
			&--unsupport {
				@include opacity(0.2);
			}
		}
	}
}


/* --------------------------------------------------- */
/* tab */
/* --------------------------------------------------- */
.tab,
.tab2 {
	display: flex;
	margin: 0 -8px 30px;
	
	&__item {
		flex: 1 1 auto;
		display: flex;
		margin: 0 8px;
		
		&__body {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 50px;
			background: $white;
			border: 1px solid $borderColor;
			cursor: pointer;
			color: $textColor;
			@include radius(6);
			@include transition;
			
			&::before {
				bottom: -8px;
				content: '';
				border-style: solid;
				border-width: 8px 8px 0;
				border-color: $baseColor transparent transparent;
				opacity: 0;
				@include transition;
				@include centering-elements(true, false); 
			}
		}
		&:hover {
			@include opacity;
		}
		&.active {
			opacity: 1;
			
			.tab__item__body,
			.tab2__item__body {
				background: $baseColor;
				border-color: $baseColor;
				color: $white;
				
				&::before {
					opacity: 1;
				}
			}
		}
	}
}



/* --------------------------------------------------- */
/* ノーリツソーシャル */
/* social */
/* --------------------------------------------------- */
.social {
	display: flex;
	
	&__item {
		position: relative;
		width: 25%;
		
		&__link {
			display: block;
		}
		&__thumb {
			position: relative;
			padding-top: 100%;
			background: $white;
			overflow: hidden;
			
			& > * {
				max-width: 100%;
				@include centering-elements(true, true);
			}
		}
		&__icon {
			position: absolute;
			right: 12px;
			bottom: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $white;
			@include circle(48);
			
			& > * {
				width: 25px;
			}
		}
	}
}



/* --------------------------------------------------- */
/* ノーリツNOW */
/* noritz-now */
/* --------------------------------------------------- */
#noritz-now {
	@include font-form;
	
	.u-font-bold {
		font-weight: bold;
		@include font-form;
	}
	.noritz-now__thumb {
		position: relative;
		margin: 0 0 15px;
		padding-top: 61.9%;
		overflow: hidden;
		@include radius(8);
		
		& > * {
			position: absolute;
			top: 0;
			left: 0;			
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			font-family: 'object-fit: cover; object-position: 50% 50%';
		}
	}
}



/* ============================================================ */
/* TOTO製品の修理受付 */
/* /repair_toto.html */
/* ============================================================ */
#repair_toto {
	.contents-lower {
		display: none;
	}
}
