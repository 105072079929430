@charset "utf-8";
/* ============================================================ */
/* 印刷用 */
/* ============================================================ */
/* --------------------------------------------------- */
/* header */
/* --------------------------------------------------- */
header {
	.inner {
		padding: 0;
	}
	.inner2 {
		flex: 0 0 10%;
	}
	.hnav01 {
		margin: 0 20px 0 0;
		
		&__item {
			margin: 0 0 0 10px;
			font-size: 0.9rem;
		}
	}
	.hnav02 {
		font-size: $m;
		
		&__item {
			margin: 0 5px 0 0;
			
			&__link {
				padding: 0 2px;
			}
		}
	}
	.gnav {
		margin: 5px 0 0;
		
		&__item {
			&__link {
				height: auto;
				padding: 5px 0.875em 15px;
				font-size: 0.9rem;
			}
		}
	}
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	font-size: 6px;
	
	&__inner {
		padding: 5px 10px;
	}
}



/* --------------------------------------------------- */
/* contents */
/* --------------------------------------------------- */
.contents {
	margin: 20px auto 40px;
	padding: 0 10px;
}
.contents-lower {
	margin: 0 auto 50px;
	padding: 0 10px;
}



/* --------------------------------------------------- */
/* footer */
/* --------------------------------------------------- */
.pagetop {
	padding: 0 10px;
}
footer {
	padding: 65px 0;
	
	.fnav {
		padding: 0 10px;
	}
	.fnav01 {
		margin: 0 0 50px;
		font-size: 0.9rem;
		
		&__ttl {
			margin: 0 0 15px;
			font-size: 1.1rem;
		}
	}
	.fnav02 {
		font-size: 0.9rem;
	}
	.copyright {
		font-size: 0.9rem;
	}
}



