@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	.inner {
		position: relative;
		display: flex;
		max-width: 1280px;
		height: 120px;
		margin: 0 auto;
		padding: 0 20px;
	}
	.inner2 {
		flex: 0 0 14.11%;
		align-self: center;
	}
	.nav {
		flex: 1 1 auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-self: flex-end;
		justify-content: flex-end;
	}
	.hnav01 {
		display: flex;
		align-items: center;
		margin: 0 30px 0 0;
		
		&__item {
			display: flex;
			align-items: center;
			margin: 0 0 0 15px;
			font-size: $xxs;
			
			&__link {
				color: $textColor;
				
				&:hover {
					color: $baseColor;
					opacity: 1;
				}
			}
		}
	}
	.hnav02 {
		display: flex;
		align-items: center;
		font-size: $xl;
		line-height: 1;
		
		&__item {
			margin: 0 10px 0 0;
			
			&:last-child {
				margin-right: 0;
			}
			&__link {
				padding: 0 5px;
				color: #808080;
				
				&:hover {
					color: $baseColor;
					opacity: 1;
				}
			}
			&--search {
				position: relative;
				
				&__icon {
					right: 0;
					padding: 0 3px;
					color: #808080;
					background: $white;
					cursor: pointer;
					@include centering-elements(false, true);
				}
				
				input[type="text"] {
					width: 19px;
					height: auto;
					padding: 0;
					background-color: transparent;
					border: none;
					border-bottom: 2px solid transparent;
					color: transparent;
					@include transition;
					
					&.open {
						width: 205px;
						padding-right: 30px;
						border-bottom-color: $baseColor;
						color: $textColor;
						cursor: auto;
						
						@media screen and (max-width: 800px) {
							width: 160px;
						}
					}				
				}
				input:-webkit-autofill {
					-webkit-box-shadow: 0 0 0px 1000px $white inset;
				}
				
				&__submit {
					right: 0;
					display: block;
					width: 26px;
					height: 20px;
					background-color: transparent;
					color: $baseColor;
					font-size: $xl;
					cursor: pointer;
					@include centering-elements(false, true);
				}
			}
		}
	}
	
	.gnav {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin: 15px 0 0;
		
		&__item {
			
			&__link {
				position: relative;
				display: block;
				height: 53px;
				padding: 10px 0.875em;
				color: $textColor;
				font-size: 1.5rem;
				line-height: 1.5;

				@media screen and (max-width: 1280px) {
					padding-left: 0.6em;
					padding-right: 0.6em;
				}
				@media screen and (max-width: 1180px) {
					padding-left: 0.45em;
					padding-right: 0.45em;
					font-size: $s;
				}
				@media screen and (max-width: 1050px) {
					padding-left: 0.3em;
					padding-right: 0.3em;
					font-size: $s;
				}
				&::after {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					content: '';
					border-bottom: 2px solid $baseColor;
					opacity: 0;
					@include transition;
				}
			}
			&:hover {
				.gnav__item__link {
					opacity: 1;
					
					&::after {
						opacity: 1;
					}
				}
			}
		}
		
		.dropdown {
			position: absolute;
			left: 20px;
			right: 20px;
			z-index: 20;
			min-height: 220px;
			background: rgba(#fef8f3, 0.95);
			border-bottom: 1px solid $baseColor;
			display: none;

			&__body {
				max-width: 1160px;
				margin: 0 auto;
				padding: 46px 20px 28px;
			}
			&__ttl {
				display: flex;
				align-items: center;
				margin: 0 0 35px;
				font-size: 3.0rem;
				line-height: 1.2;
				
				.icon-circle-arrow-right {
					margin: 0 0 0 15px;
					color: $baseColor;
					font-size: $xxxl;
				}
				&__link {
					color: $textColor;
				}
			}
			
			&__menu {
				&__item {
					color: $textColor;
					
					.icon-keyboard_arrow_right {
						position: relative;
						top: 3px;
						font-size: $xl;
					}
					
					&:hover {
						color: $baseColor;
						opacity: 1;
						
						.dropdown__menu__item__img {
							@include opacity;
						}
					}
				}
			}
		}
	}
	
}

.product .gnav__item--product,
.showroom .gnav__item--showroom,
.aftersupport .gnav__item--aftersupport,
.special .gnav__item--special,
.info .gnav__item--info,
.company .gnav__item--company {
	.gnav__item__link::after {
		opacity: 1;
	}
	
}

