@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	
	.contents {
		margin: 0;
		order: 0;
	}
	.row-wide {
		padding: 60px 15px;
		
		&.bg-gray {
			background: #f3f3f3;
		}
		&.bg-red {
			background: #f55333;
		}
		&.bg-red-lighten {
			background: #feead6;
		}
	}
	
	.index-ttl01,
	.index-ttl02,
	.index-ttl03 {
		color: $baseColor;
		text-align: center;
		line-height: 1.5;
	}
	
	.index-ttl01,
	.index-ttl02 {
		margin: 0 0 20px;
		font-size: 2.6rem;
	}
	.index-ttl03 {
		margin: 0 0 20px;
		font-size: $xl;
	}
	
	
	/* .index-visual
	----------------------------------------------------------- */
	.index-visual {
		position: relative;
		
		&::before {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			content: '';
			height: 20px;
			background: url(/common/img/index/bg_gray_wave.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
		
		.slick-prev,
		.slick-next {
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 21px;
			height: 40px;
			background: rgba($white, 0.8);
			color: $baseColor;
			font-size: 1.5rem;
			
			&::before {
				display: none;
			}
		}
		.slick-prev { left: 0; }
		.slick-next { right: 0; }
		.slick-dots {
			bottom: 40px;
			line-height: 1;
			
			li {
				width: 8px;
				height: 8px;
				margin: 0 3px;
				line-height: 1;
				
				button {
					padding: 0;
					background: rgba($white, 0.5);
					@include circle(8);
					
					&::before {
						display: none;
					}					
				}
				
				&.slick-active {
					button {
						background: $white;
					}
				}
			}
		}
		.slick-dotted.slick-slider {
			margin-bottom: 0;
		}
		.slick-slide {
			position: relative;
			height: unset;
		}
		
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			padding: 0 0 60px;
			
			.btn-cm {
				width: 160px;
				min-height: 40px;
			}
			.index-visual__btn-play,
			.index-visual__btn-volume {
				position: absolute;
				top: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				background: none;
				border: 1px solid $white;
				color: $white;
				@include circle(30);
			}
			
			.index-visual__btn-play {
				right: 50px;
				font-size: $s;
				
				&--play {
					.icon-pause::before {
						content: "\f04b";
					}
				}
			}
			.index-visual__btn-volume {
				right: 10px;
				font-size: $l;
				
				&--off {
					.icon-volume_up::before {
						content: "\e04e";
					}
				}
			}
		}
		
		&__slider {
			&__mv {
				background: #000;
				
				&__thumb {
					position: absolute;
					top: 0;
				}
				&__movie,
				&__thumb {
					margin-top: -5px;
				}
			}
		}
	}
	
	
	/* .important
	----------------------------------------------------------- */
	.important {
		margin: 0 0 40px;
		background: $white;
		border: 1px solid $borderColor;
		overflow: hidden;
		@include radius(8);
		
		&__ttl {
			padding: 5px 10px;
			background: $baseColor;
			border-bottom: 1px solid $borderColor;
			color: $white;
			font-size: $m;
			text-align: center;
			@include font-bold;
		}
		&__body {
		}
		&__content {
			display: flex;
			padding: 15px;
			line-height: 1.5;
			
			&__link {
				display: flex;
				color: $textColor;
				
				&__date {
					flex: 0 0 auto;
					position: relative;
					top: 2px;
					width: 75px;
					font-size: $xxs;
				}
				&__text {
					flex: 1 1 auto;
				}
				.icon-angle-right {
					align-self: center;
					margin: 0 0 0 8px;
					color: $baseColor;
					font-size: $l;
				}
			}
			
			&:not(:first-child) {
				border-top: 1px solid $borderColor;
			}
		}
	}


	/* .pickup
	----------------------------------------------------------- */
	.pickup {
		display: flex;
		flex-wrap: wrap;
		
		&__item {
			width: calc((100% - 20px) / 2);
			margin: 0 5px 20px;
			font-size: $m;
			text-align: center;
			@include font-bold;
		}
	}


	/* .pickup-column
	----------------------------------------------------------- */
	.pickup-column {
		background: $white;
		border: 1px solid $borderColor;
		overflow: hidden;
		@include radius(8);
		@include font-form;
		
		&__body {
			padding: 10px 15px;
			border-top: 1px solid $borderColor;
		}
		.u-font-bold {
			font-weight: bold;
			@include font-form;
		}
	}
	
	
	/* .noritz-social
	----------------------------------------------------------- */
	.noritz-social {
		position: relative;
		
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			content: '';
			height: 40px;
			background: url(/common/img/index/bg_white_wave_sp.png) top center no-repeat #f3f3f3;
			background-size: 100% 100%;
		}
	}
	
	
	/* .product
	----------------------------------------------------------- */
	.product {
		position: relative;
		
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			content: '';
			height: 40px;
			background: url(/common/img/index/bg_orange_wave.png) top center no-repeat $white;
			background-size: 100% 100%;
		}
		
		&__column {
			display: flex;
			flex-direction: column;
			
			&__item {
				display: flex;
				margin-left: -2%;
				margin-right: -2%;

				& > * {
					margin-left: 2%;
					margin-right: 2%;
					width: 46%;
				}
				
				&--lg {
					
					& > * {
						width: 100%;
					}
					
				}
			}
		}
	}
	
	
	/* .product02
	----------------------------------------------------------- */
	.product02 {
		background: url(/common/img/index/bg_cta_sp.png) top center no-repeat;
		background-size: cover;
		
		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 165px;
			background: rgba($white, .8);
			color: $textColor;
			font-size: $l;
			text-align: center;
			line-height: 1.5;
			@include radius(8);
			@include font-bold;

			&__icon {
				margin: 0 0 10px;
			}
		}
	}
	

	/* .product-notice
	----------------------------------------------------------- */
	.product-notice {
		&__ttl {
			margin: 0 0 30px;
			
			&__list {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	
	
	/* .news
	----------------------------------------------------------- */
	.news {
		&__ttl {
			margin: 0 0 30px;
			
			&__link {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.news-list {
			.label {
				background: rgba($white, .6);
			}
		}
	}
	
	
	/* .bnr-slider
	----------------------------------------------------------- */
	.bnr-slider {
		padding: 0 22px;
		
		&__item {
			padding: 0 5px;
		}
		.slick-prev,
		.slick-next {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $baseColor;
			font-size: $l;
			
			&::before {
				display: none;
				content: '';
			}
		}
		.slick-prev { left: 0; }
		.slick-next { right: 0; }
	}
	
	/* .YouYube MV 2021.06
	----------------------------------------------------------- */

	#ytContainer {
		height: 0;
		overflow: hidden;
		position: relative;
		padding-bottom: 52.174vh;
		> .ytScreen {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			cursor:pointer;
		}
	}

	#yt70th {
		position: absolute;
		right: -13%;
		width: 72vh;
		height: 57vh;
		transform: translateY(-6.825vw);
	}

	#ytModal {
		position: fixed;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(255, 255, 255, 0.9);
		top: 0;
		left: 0;
		z-index: 11;
		transition: opacity 0.5s;
		&:not(.active) {
			opacity: 0;
			pointer-events: none;
		}
		&.active {
			opacity: 1;
			pointer-events: auto;
		}
		iframe {
			width: 100vw;
			height: 56.25vw;
				margin-bottom:60px;
		}
	}

	.ytClose {
		display: block;
		width: 40px;
		height: 40px;
		position: relative;
		border:none;
		background:transparent;
		margin:0 0 20px auto;
		cursor:pointer;
		&::before,
		&::after {
			content:'';
			display:block;
			width:32px;
			height:2px;
			background:#000;
			position:absolute;
			top:19px;
			left:4px;
		}
		&::before{
			transform:rotate(45deg);
		}
		&::after{
			transform:rotate(-45deg);
		}
	}
	
	
}