@charset "utf-8";
/* ============================================================ */
/* 知る・楽しむ */
/* /special/ */
/* ============================================================ */
.special--row {
	
	
	.special-box {
		display: block;
		margin: 0 0 20px;
		padding: 40px 15px;
		border: 1px solid $borderColor;
		color: $textColor;
		@include radius(8);

 		&__img {
			margin: 0 0 30px;
			text-align: center;
			
			img {
				max-width: 165px;
				width: 100%;
			}
		}
		
		&__body {
			display: flex;
			flex-direction: column;
		}

		&__ttl {
			margin: 0 0 15px;
			font-size: $l;
			line-height: 1.5;
			@include font-bold;
		}
		&__text {
			margin: 0 0 40px;
		}
		&__link {
			align-self: center;
			color: $textColor;
			font-size: $l;
			text-align: center;
			@include font-bold;
			
			.icon-circle-arrow-right {
				margin: 0 0 0 10px;
				color: $baseColor;
			}
		}
		&--ecorelakirei {
			background: url(/common/img/special/pic_ecorelakirei_sp.png) left bottom no-repeat;
			background-size: 100% auto;
			text-align: center;
			
			.special-box__link,
			.special-box__link .icon-circle-arrow-right {
				color: $white;
			}
		}
	}
}


/* ============================================================ */
/* やっぱり家のおふろが、いちばんです。 */
/* /special/cmcontents/index.html */
/* ============================================================ */
.special_cmcontents {

	.cm {
		position: relative;
		background: #e7380d;
		
		&::before {
			position: absolute;
			left: 0;
			right: 0;
			top: -146px;
			z-index: -1;
			content: '';
			height: 146px;
			background: url(/special/cmcontents/img/bg_red_up_sp.png) repeat-x;
			background-size: contain;
			background-position: bottom;
		}
		
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -70px;
			z-index: 1;
			content: '';
			height: 70px;
			background: url(/special/cmcontents/img/bg_kashi_up_sp.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
	}
	
	.kashi {
		position: relative;
		padding-top: 40px;
		padding-bottom: 60px;
		background: url(/special/cmcontents/img/bg_kashi_sp.png) center no-repeat;
		background-size: cover;

		
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 0;
			content: '';
			height: 36px;
			background: url(/special/cmcontents/img/bg_kashi_down_sp.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
	}
	
	.profile {

	}
	
	.takahashi {
		position: relative;
		margin-bottom: 70px;
		background: #e7380d;
		
		&::before {
			position: absolute;
			left: 0;
			right: 0;
			top: -50px;
			z-index: -1;
			content: '';
			height: 146px;
			background: url(/special/cmcontents/img/bg_red_up_sp.png) repeat-x;
			background-size: contain;
		}
		
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -50px;
			z-index: -1;
			content: '';
			height: 73px;
			background: url(/special/cmcontents/img/bg_red_down_sp.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
	}
	
	.modalbg {
		background: #fff;
		filter: alpha(opacity=90);
		-moz-opacity: 0.90;
		opacity: 0.90;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		display: none;
	}

	.modalcontents {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20;
		width: 100%;
		padding: 0 2%;
		display: none;
		
		.modaltxt {
			font-size: 133.33%;
			color: #fff;
			text-align: center;
		}
		.modalmovie {
			margin-top: 20px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
			-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
			-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
			height: auto;
		}
		.modalclosebtn {
			position: absolute;
			top: -20px;
			right: 2%;
		}
		
		iframe {
			width: 100%;
			height: 100%;
		}
	}
}

