@charset "utf-8";
/* ============================================================ */
/* ショールームを探す 共通 */
/* /showroom/ */
/* ============================================================ */
.showroom {
	
	.exhibit {
		&__tab {
			display: flex;
			margin: 0 -8px 30px;

			&__item {
				flex: 1 1 auto;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 50px;
				margin: 0 8px;
				background: $white;
				border: 1px solid $borderColor;
				line-height: 1.4;
				cursor: pointer;
				@include radius(6);
				@include transition;
				
				[class^="icon-"] {
					margin: 0 10px 0 0;
					font-size: 3.0rem;
				}

				&::before {
					bottom: -8px;
					content: '';
					border-style: solid;
					border-width: 8px 8px 0;
					border-color: $baseColor transparent transparent;
					opacity: 0;
					@include transition;
					@include centering-elements(true, false); 
				}
				&:hover {
					@include opacity;
				}
				&.active {
					background: $baseColor;
					border-color: $baseColor;
					color: $white;
					opacity: 1;

					&::before {
						opacity: 1;
					}
				}
			}
		}
	}
	
	
	.zoom {
		position: relative;
		cursor: pointer;
		padding-top: 75%;
		@include transition;
		
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			content: '';
			border: 1px solid $borderColor;
		}
		
		&:hover {
			@include opacity;
		}
		& > img {
			max-width: 100%;
			max-height: 100%;
			@include centering-elements(true, true);
		}
		&__icon {
			position: absolute;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			background: $baseColor;
			color: $white;
		}
	}

	
	
	/* gallery-slide
	----------------------------------------------------------- */
	.gallery-slide {
		
		.slick-dots {
			position: absolute;
			bottom: -25px;
			display: block;
			width: 100%;
			padding: 0;
			margin: 0;
			list-style: none;
			text-align: center;
			
			li {
				width: 8px;
				height: 8px;
				margin: 0 4px;
				cursor: pointer;
				
				button {
					width: 8px;
					height: 8px;
					padding: 0;
					background: #e8e8e8;
					border-radius: 100%;
					overflow: hidden;
					
					&::before {
						display: none;
					}
				}
				&.slick-active {
					button {
						background: #f6af9e;
					}
				}
			}
		}
		
		.slick-prev,
		.slick-next {
			z-index: 1;
			width: 32px;
			height: 32px;
			font-size: 2.6rem;
			color: $white;
			background: rgba($baseColor, .5);
			
			&::before {
				display: none;
			}
		}
		.slick-prev { left: -30px; }
		.slick-next { right: -30px; }
	}
	
	
	/* 設備
	----------------------------------------------------------- */
	.facility {
		margin: 0 -2px;
		
		&__item {
			display: inline-flex;
			align-items: center;
			width: calc((100% - 31px) / 3);
			width: 105px;
			height: 40px;
			margin: 0 2px 10px;
			padding: 0 10px;
			border: 1px solid $baseColor;
			color: $baseColor;
			font-size: $xxxs;
			line-height: 1.4;
			@include radius(8);
			
			[class^="icon-"] {
				margin: 0 5px 0 0;
				font-size: $xxxl;
			}
		}
	}
	
	
	/* 地図
	----------------------------------------------------------- */
	.map {
		position: relative;
		padding-bottom: 100%;
		height: 0;
		overflow: hidden;

		&__body {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.map,
	.map * {
		max-width: none;
	}
	
	
	/* 展示品 モーダル表示
	----------------------------------------------------------- */
	.modal {
		.slider {
			.slick-slide {
				img {
					max-height: 80vh;
				}
			}
		}
	}
	
	
}



/* ============================================================ */
/* ショールームを探す トップ */
/* /showroom/ */
/* ============================================================ */
#showroom_index {
	
	.btn--anchorlink {
		min-height: 0;
		height: 50px;
		
		.icon-showroom-find,
		.icon-showroom-check,
		.icon-showroom-human {
			position: relative;
			top: 2px;
			margin: 0 10px 0 0;
			font-size: 4.0rem;
		}
		.icon-keyboard_arrow_down {
			margin: 0 0 0 10px;
			font-size: $l;
		}
	}
	.btn--anchorlink-active {
		background: $baseColor;
		border-color: $baseColor;
		color: $white;
	}
	
}


