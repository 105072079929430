@charset "utf-8";
/* ============================================================ */
/* 知る・楽しむ */
/* /special/ */
/* ============================================================ */
.special--row {
	
	.special-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 25px;
		padding: 30px 0;
		border: 1px solid $borderColor;
		color: $textColor;
		@include radius(8);
		
		&__img {
			width: 40%;
			padding: 0 0 0 30px;
			text-align: center;
			
			img {
				max-width: 230px;
				width: 100%;
			}
		}
		&__body {
			display: flex;
			flex-direction: column;
			width: 58%;
			padding: 0 30px 0 0;
			
			& > * {
				flex: 0 0 auto;
			}
		}
		&__ttl {
			margin: 0 0 15px;
			font-size: $l;
			@include font-bold;
		}
		&__text {
			margin: 0 0 40px;
		}
		&__link {
			align-self: flex-end;
			color: $textColor;
			font-size: $l;
			@include font-bold;
			
			.icon-circle-arrow-right {
				margin: 0 0 0 10px;
				color: $baseColor;
			}
		}
		&--ecorelakirei {
			min-height: 280px;
			background: url(/common/img/special/pic_ecorelakirei.png) top right no-repeat;
			background-size: auto 100%;
			
			.special-box__link {
				margin-right: 30px;
				color: $white;
				
				.icon-circle-arrow-right {
					color: $white;
				}
			}
		}
		
	}
}

/* ============================================================ */
/* やっぱり家のおふろが、いちばんです。 */
/* /special/cmcontents/index.html */
/* ============================================================ */
.special_cmcontents {

	.cm {
		position: relative;
		background: #e7380d;
		
		&::before {
			position: absolute;
			left: 0;
			right: 0;
			top: -220px;
			z-index: -1;
			content: '';
			height: 320px;
			background: url(/special/cmcontents/img/bg_red_up.png) repeat-x;
		}
		
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -195px;
			z-index: 1;
			content: '';
			height: 196px;
			background: url(/special/cmcontents/img/bg_kashi_up.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
	}
	
	.kashi {
		position: relative;
		padding-top: 195px;
		padding-bottom: 124px;
		background: url(/special/cmcontents/img/bg_kashi.png) center no-repeat;
		background-size: cover;

		
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 0;
			content: '';
			height: 124px;
			background: url(/special/cmcontents/img/bg_kashi_down.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
	}
	
	.profile {

	}
	
	.takahashi {
		position: relative;
		margin-bottom: 70px;
		background: #e7380d;
		
		&::before {
			position: absolute;
			left: 0;
			right: 0;
			top: -50px;
			z-index: -1;
			content: '';
			height: 320px;
			background: url(/special/cmcontents/img/bg_red_up.png) repeat-x;
		}
		
		&::after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -50px;
			z-index: -1;
			content: '';
			height: 320px;
			background: url(/special/cmcontents/img/bg_red_down.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
	}
	
	.modalbg {
		background: #fff;
		filter: alpha(opacity=90);
		-moz-opacity: 0.90;
		opacity: 0.90;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		display: none;
	}

	.modalcontents {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20;
		width: 940px;
		display: none;
	}

	.modalcontents .modaltxt {
		font-size: 133.33%;
		color: #fff;
		text-align: center;
	}

	.modalcontents .modalmovie {
		margin-top: 20px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		height: 528px;
	}

	.modalcontents .modalclosebtn {
		position: absolute;
		top: -20px;
		right: 0;
	}
	
	@media screen and (max-width: 980px) {
		.modalcontents {
			width: 100%;
			padding: 0 2%;

			.modalclosebtn {
				right: 2%;
			}

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}



}


