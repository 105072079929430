@charset "utf-8";
/* ============================================================ */
/* 商品情報 共通 */
/* /product/ */
/* ============================================================ */
.product {
	

	/* ●●シリーズ
	----------------------------------------------------------- */
	.series-info {
		&__thumb {
			text-align: center;
		}
		&__function {
			&__body {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				margin: 0 -4px;
				max-width: none;
			}
			&__item {
				position: relative;
				margin: 0 4px 7px;
				width: calc((100% - 72px) / 9);
				
				&--col2 {
					width: calc((100% - 72px) / 9 * 2 + 8px);
				}
				
				&__note {
					position: absolute;
					right: -4px;
					bottom: 8px;
					width: 12px;
					height: 12px;
					line-height: 1;
				}
			}
		}
		&__mark {
			display: flex;
			flex-wrap: wrap;
			
			&__item {
				position: relative;
				flex: 0 0 auto;
				margin: 0 8px 8px 0;
				box-sizing: content-box;
				line-height: 1;
				
				& > * {
					width: 100%;
					vertical-align: middle;
				}
				&--eco-j { width: 135px; }
				&--q21 { width: 45px; }
				&--teinox { width: 45px; }
				&--yuteria { width: 45px; }
				&--souhoukou { width: 45px; }
				&--intelligent { width: 45px; }
				&--e-con { width: 45px; }
				&--kanki-kyokusyo { width: 45px; }
				&--kanki-24h { width: 45px; }
				&--green { width: 135px; }
				&--use-period { width: 80px; }
				&--bl-bs { width: 125px; }
				&--bl-bs2 { width: 65px; }
				&--bl { width: 45px; }
				&--gp { width: 45px; }
				&--xxxxx { width: 45px; }
				&--warranty3 { width: 45px; }
				&--syouene { width: 152px; }
				&--l2_tech { width: 80px; }
				
				&__note {
					position: absolute;
					top: 0;
					right: 0;
					width: 12px;
					height: 12px;
					line-height: 1;
				}
			}
		}
	}
	
}



/* ============================================================ */
/* 給湯機器トップ */
/* /product/kyutoki/ */
/* ============================================================ */
#product_kyutoki_index {
	
	/*----- あなたにぴったりの給湯器をお勧めします -----*/	
	.recommend {
		&__number {
			margin: 0 0 5px;
			font-size: $l;
			@include font-bold;
		}
		&__product-name {
			margin: 0 0 10px;
			font-size: $xxxl;
			@include font-bold;
		}
		&__function {
			display: flex;
			text-align: center;
			line-height: 1.5;
			
			&__icon {
				font-size: $xxs;
				
				& > * {
					display: block;
				}
				[class^="icon-"] {
					color: $baseColor;
					font-size: 4.5rem;
				}
			}
			&__add {
				margin: 10px 10px 0;
				font-size: $xl;
			}
		}
	}
	
	
	/*----- ノーリツの給湯器ならこんなことができる！ -----*/
	.balloon {
		position: relative;
		background: $white;
		border: 1px solid $borderColor;
		font-size: $l;
		text-align: center;
		line-height: 1.5;
		cursor: pointer;
		@include font-bold;
		@include radius(8);
		@include transition;
		
		&::before,
		&::after {
			position: absolute;
			bottom: 20px;
			content: '';
			border-style: solid;
			border-width: 9px 10px 9px 0;
			border-color: transparent;
			@include transition;
		}
		&::before {
			left: -10px;
			border-right-color: $borderColor;
		}
		&::after {
			left: -9px;
			border-right-color: $white;
		}
		&:hover {
			background: $baseColor;
			border-color: $baseColor;
			color: $white;
			
			&::before,
			&::after {
				border-right-color: $baseColor;
			}
		}
		
		& > * {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 125px;
		}
		
	}
	
	
	/*----- クイズ お風呂の「正しい入り方」 -----*/
	.quiz {
		&__item {
			display: flex;
			align-items: center;
			
			&:not(:last-child) {
				margin: 0 0 40px;
			}
			
			&__alphabet {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 20px 0 0;
				border: 2px solid $baseColor;
				color: $baseColor;
				font-size: $xxxl;
				line-height: 1;
				@include circle(40);
			}
		}
	}
	
	.quiz-btn {
		position: relative;

		input[type="checkbox"] {
			display: none;
		}

		input[type="checkbox"] + .quiz-btn__body  {
			.quiz-btn__answer1,
			.quiz-btn__answer2 {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 360px;
				height: 65px;
				font-size: $m;
				cursor: pointer;
				@include radius(8);
				@include font-bold;
				@include transition;
			}
			
			.quiz-btn__answer1 {
				background: $baseColor;
				border: 1px solid $borderColor;
				color: $white;
			}
			.quiz-btn__answer2 {
				display: none;
				background: $white;
				border: 1px solid $baseColor;
				color: $baseColor;
				
				.quiz-btn__answer2__alphabet {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid $baseColor;
					@include circle(24);
				}
			}
		}
		input[type="checkbox"]:checked + .quiz-btn__body {
			.quiz-btn__answer1 {
				display: none;
			}
			.quiz-btn__answer2 {
				display: flex;
			}
		}
	}

}



/* ============================================================ */
/* 無線LAN対応リモコン */
/* /product/kyutoki/gus/remocon/06.html */
/* ============================================================ */
#product_kyutoki_gus_remocon_06 {
	
	.useful-content {
		display: flex;
		
		&__place {
			flex: 0 0 auto;
			width: 140px;
			margin: 0 -10px 0 0;
		}
		&__img {
			flex: 0 0 auto;
			width: 300px;
			margin: 0 30px 0 0;
		}
		&__text {
			flex: 1 1 auto;
			align-self: center;
		}
		
		&--noplace {
			.useful-content__img {
				margin-left: 130px;
			}
		}
	}	
}



/* ============================================================ */
/* 給湯機器　戸建住宅用 ⁄ 集合住宅用 ハイブリッド給湯・暖房システム　お客さまの声、Q＆A */
/* /product/kyutoki/hybrid/qa.html */
/* ============================================================ */
#product_kyutoki_hybrid_qa {
	.faq-list {
		&__question {
			display: flex;
			align-items: center;
			margin: 0 0 10px;
			
			&::before {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				content: 'Q';
				margin: 0 10px 0 0;
				background: $baseColor;
				color: $white;
				font-size: $l;
				@include circle(30);
				@include font-normal;
			}
			font-size: $m;
			@include font-bold;
		}
		&__answer {
			display: flex;
			margin: 0 0 40px;
			
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				flex: 0 0 auto;
				display: block;
				content: 'A.';
				margin: 0 10px 0 5px;
				color: $baseColor;
				font-size: $xxl;
				@include font-bold;
			}
		}
	}
}



/* ============================================================ */
/* 取替用キッチン機器 共通  */
/* /product/kitchen01/ */
/* ============================================================ */
.product_kitchen01 {
	.balloon {
		position: relative;
		margin: 25px 0 0;
		padding: 10px;
		border: 1px solid $baseColor;
		color: $baseColor;
		font-size: $m;
		text-align: center;
		@include font-bold;
		@include radius(8);
		
		&::before,
		&::after {
			content: '';
			border-style: solid;
			border-width: 0 10px 10px;
			border-color: transparent;
			@include centering-elements(ture, false);
		}
		&::before {
			top: -10px;
			border-bottom-color: $baseColor;
		}
		&::after {
			top: -9px;
			border-bottom-color: $white;
		}
	}
	
}



/* ============================================================ */
/* 取替用キッチン機器　レンジフード　お手入れ簡単ノーリツのレンジフード */
/* /product/kitchen01/rangehood/oteire.html */
/* ============================================================ */
#product_kitchen01_rangehood_oteire {
	
	.icon-number {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		margin: 0 10px 0 0;
		background: #57c3e0;
		color: $white;
		@include radius(4);
		@include font-bold;
	}
}



/* ============================================================ */
/* キッチントップ */
/* /product/kitchen/ */
/* ============================================================ */
#product_kitchen_index {
	
	.kitchen-movie {
		position: relative;
		cursor: pointer;
		@include transition;
		
		&:hover {
			@include opacity;
		}
		
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 20px;
			color: $white;
			font-size: $m;
			text-align: center;
			text-shadow: 0 0 15px rgba(#000, 0.3);
			line-height: 1.4;
		}
		&__ttl {
			flex: 1 1 auto;
			font-size: $l;
		}
		&__icon {
			flex: 0 0 auto;
			margin: 20px 0;
			font-size: 4.8rem;
		}
		&__text {
			flex: 0 0 auto;
		}
	}
}



/* ============================================================ */
/* キッチン：レシピア プラス */
/* /product/kitchen/recipia_plus/ */
/* ============================================================ */
#product_kitchen_recipia_plus_index {
	
	.mylife {
		background: url(/common/img/product/kitchen/recipia_plus/bg_mylife.png) 0 0 no-repeat;
		background-size: 100% auto;
		
		&__body {
			margin: 0 95px;
		}
		&__content {
			padding: 10px 25px 25px;
			background: $white;
			
			&__link {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 0 0 15px;
				font-size: $l;
				line-height: 1.5;
				@include font-bold;
			}
		}
	}
	
}

/* ============================================================ */
/* キッチン：レシピア */
/* /product/kitchen/recipia/ */
/* ============================================================ */
.product_kitchen_recipia {
	.lnav__level1__item--recipia {
	
		.lnav__level1__item__link {
			background: #f07355;
			border-color: #f07355;
			color: $white;
			opacity: 1;

			&::before {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}



/* ============================================================ */
/* キッチン：ジュ・テーム */
/* /product/kitchen/jtm/ */
/* ============================================================ */
#product_kitchen_jtm_index {
	.box--jtm {
		background: #fffcdb;
	}
}


/* ============================================================ */
/* バスルーム */
/* /product/bathroom/*/
/* ============================================================ */
.product_bathroom {
	
	.equipment-set {
		overflow: hidden;
		@include radius(8);
		
		&__slider {
			margin: 0 -2px;
		}
		
		&__item {
			position: relative;
			margin: 0 2px;
			text-align: center;
			cursor: pointer;
			
			&::before {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				content: '';
				height: 150px;
				background: linear-gradient(to bottom, rgba(#000, 0) 0%, rgba(#000, 0.4) 100%);
			}
			
			&__btn1 {
				bottom: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				max-width: 360px;
				height: 50px;
				border: 1px solid #d2d2d2;
				color: $white;
				@include centering-elements(true, false);
				@include radius(6);
				@include transition;
			}
			&__btn2 {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				max-width: 360px;
				height: 50px;
				margin: 20px auto 0;
				border: 1px solid #d2d2d2;
				color: $white;
				@include radius(6);
			}
			&__content {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				padding: 40px 60px 24px;
				background: rgba(#000, .7);
				color: $white;
				opacity: 0;
				visibility: hidden;
				@include transition;
				
				&__body {
					height: 100%;
					text-align: left;
					overflow: hidden;
				}
			}
			&__price {
				font-size: $xxxl;
			}
			
			&:hover {
				.equipment-set__item__btn1 {
					opacity: 0;
					visibility: hidden;
				}
				.equipment-set__item__content {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		
		.slick-prev,
		.slick-next {
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 64px;
			background: rgba($baseColor, 0.5);
			color: $white;
			font-size: 2.6rem;
			@include transition;

			&:hover {
				@include opacity;
			}

			&::before {
				display: none;
			}
		}
		.slick-prev { left: 2px; }	
		.slick-next { right: 2px; }	
	}
	
	
}



/* ============================================================ */
/* 洗面化粧台 共通 */
/* /product/sanitary/ */
/* ============================================================ */
.sanitary-plan {
	&__img {
		float: left;
		width: 36%;
		margin: 0 20px 0 0;
	}
	&__label {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 0 10px;
		overflow: hidden;
	
		.label {
			display: flex;
			width: 100%;
			height: 35px;
			margin: 0 0 10px;
			line-height: 1.2;
		}
	}
	&__text {
		margin-left: calc(36% + 20px);
	}
}



/* ============================================================ */
/* 洗面化粧台：シャンピーヌ共通 */
/* /product/sanitary/shampine_new/ */
/* ============================================================ */
.product_sanitary_shampine_new {
	.eco-faucet {
		position: relative;
		
		&__text {
			position: absolute;
			top: 0;
			left: 34%;
		}
	}
}



/* ============================================================ */
/* ミスト保湿浴のススメ 02：身体にやさしい入浴スタイル */
/* /product/onsui_danbou/mist_dryhot/susume/mist02.html */
/* ============================================================ */
#product_onsui_danbou_mist_dryhot_susume_mist02 {
	.box--green {
		background: #effbe4;
	}
	
	.hoshitsuyoku-table {
		width: 500px;
		max-width: 48%;
	}
}



/* ============================================================ */
/* ミスト保湿浴のススメ 07：おふろでエステ 2 */
/* /product/onsui_danbou/mist_dryhot/susume/mist07.html */
/* ============================================================ */
#product_onsui_danbou_mist_dryhot_susume_mist07 {
	
	.box--aroma {
		background: #e2ffc6;
	}
	
}




