@charset "utf-8";
/* ============================================================ */
/* 商品情報 共通 */
/* /product/ */
/* ============================================================ */
.product {
	

	/* ●●シリーズ
	----------------------------------------------------------- */
	.series-info {
		&__thumb {
			order: 0;
			text-align: center;
		}
		&__summary {
			order: 4;
		}
		&__function {
			order: 1;
			
			&__body {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				margin: 0 -4px;
				max-width: none;
			}
			&__item {
				position: relative;
				margin: 0 4px 10px;
				width: calc((100% - 40px) / 5);
				
				&--col2 {
					width: calc((100% - 40px) / 5 * 2 + 8px);
				}
				
				&__note {
					position: absolute;
					right: -4px;
					bottom: 8px;
					width: 12px;
					height: 12px;
					line-height: 1;
				}
			}
		}
		&__mark { //マーク
			order: 5;
			display: flex;
			flex-wrap: wrap;
			
			&__item {
				position: relative;
				flex: 0 0 auto;
				margin: 0 5px 5px 0;
				box-sizing: content-box;
				line-height: 1;
				
				& > * {
					width: 100%;
					vertical-align: middle;
				}
				&--eco-j { width: 90px; }
				&--q21 { width: 32px; }
				&--teinox { width: 30px; }
				&--yuteria { width: 36px; }
				&--souhoukou { width: 36px; }
				&--intelligent { width: 36px; }
				&--e-con { width: 36px; }
				&--kanki-kyokusyo { width: 36px; }
				&--kanki-24h { width: 36px; }
				&--green { width: 90px; }
				&--use-period { width: 53px; }
				&--bl-bs { width: 85px; }
				&--bl-bs2 { width: 45px; }
				&--bl { width: 30px; }
				&--gp { width: 30px; }
				&--xxxxx { width: 30px; }
				&--warranty3 { width: 32px; }
				&--syouene { width: 100px; }
				&--l2_tech { width: 53px; }
				
				&__note {
					position: absolute;
					top: 0;
					right: 0;
					width: 10px;
					height: 10px;
					line-height: 1;
				}
			}
		}
		&__note {
			order: 2;
		}
		&__sup {
			order: 3;
		}
	}	
	
}


/* ============================================================ */
/* 給湯機器トップ */
/* /product/kyutoki/ */
/* ============================================================ */
#product_kyutoki_index {
	
	/*----- あなたにぴったりの給湯器をお勧めします -----*/	
	.recommend {
		&__number {
			margin: 0 0 5px;
			font-size: $xxxs;
			@include font-bold;
		}
		&__product-name {
			margin: 0 0 10px;
			@include font-bold;
		}
		&__function {
			display: flex;
			justify-content: center;
			text-align: center;
			line-height: 1.5;
			
			&__icon {
				font-size: $xxs;
				
				& > * {
					display: block;
				}
				[class^="icon-"] {
					color: $baseColor;
					font-size: 3.8rem;
				}
			}
			&__add {
				margin: 10px 10px 0;
				font-size: $xl;
			}
		}
	}
	
	
	/*----- ノーリツの給湯器ならこんなことができる！ -----*/
	.balloon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 80px;
		background: $white;
		border: 1px solid $borderColor;
		font-size: $m;
		text-align: center;
		line-height: 1.3;
		cursor: pointer;
		@include font-bold;
		@include radius(8);
		@include transition;
	}
	
	
	/*----- クイズ お風呂の「正しい入り方」 -----*/
	.quiz {
		&__item {
			display: flex;
			align-items: center;
			
			&:not(:last-child) {
				margin: 0 0 20px;
			}
			
			&__alphabet {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 15px 0 0;
				border: 2px solid $baseColor;
				color: $baseColor;
				font-size: $xl;
				@include circle(30);
			}
		}
	}
	
	
	.quiz-btn {
		width: 100%;
		
		input[type="checkbox"] {
			display: none;
		}

		input[type="checkbox"] + .quiz-btn__body  {
			.quiz-btn__answer1,
			.quiz-btn__answer2 {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 60px;
				font-size: $m;
				cursor: pointer;
				@include radius(8);
				@include font-bold;
				@include transition;
			}
			
			.quiz-btn__answer1 {
				background: $baseColor;
				border: 1px solid $borderColor;
				color: $white;
			}
			.quiz-btn__answer2 {
				display: none;
				background: $white;
				border: 1px solid $baseColor;
				color: $baseColor;
				
				.quiz-btn__answer2__alphabet {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid $baseColor;
					@include circle(24);
				}
			}
		}
		input[type="checkbox"]:checked + .quiz-btn__body {
			.quiz-btn__answer1 {
				display: none;
			}
			.quiz-btn__answer2 {
				display: flex;
			}
		}
	}
	


}



/* ============================================================ */
/* 無線LAN対応リモコン */
/* /product/kyutoki/gus/remocon/06.html */
/* ============================================================ */
#product_kyutoki_gus_remocon_06 {
	
	.useful-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		
		&__place {
			width: 25%;
			margin: 0 -10px 20px 0;
		}
		&__img {
			width: 75%;
			margin: 0 0 20px;
		}
		&--noplace {
			.useful-content__img {
			}
		}
	}	
}



/* ============================================================ */
/* 給湯機器　戸建住宅用 ⁄ 集合住宅用 ハイブリッド給湯・暖房システム　お客さまの声、Q＆A */
/* /product/kyutoki/hybrid/qa.html */
/* ============================================================ */
#product_kyutoki_hybrid_qa {
	.faq-list {
		&__question {
			display: flex;
			margin: 0 0 5px;
			
			&::before {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				content: 'Q';
				margin: 0 10px 0 0;
				background: $baseColor;
				color: $white;
				font-size: 1.5rem;
				@include circle(24);
				@include font-normal;
			}
			@include font-bold;
		}
		&__answer {
			display: flex;
			margin: 0 0 30px;
			
			&:last-child {
				margin-bottom: 0;
			}
			&::before {
				flex: 0 0 auto;
				display: block;
				content: 'A.';
				margin: 0 10px 0 3px;
				color: $baseColor;
				font-size: $l;
				@include font-bold;
			}
		}
	}
}



/* ============================================================ */
/* 温水暖房機器　浴室暖房・乾燥・ミスト　天井カセット形 浴室暖房乾燥機 */
/* /product/onsui_danbou/mist_dryhot/celinglineup.html */
/* ============================================================ */
#product_onsui_danbou_mist_dryhot_celinglineup {
	
	.lineup03 {
		&__thumb {
			width: 40%;
			
			& > * {
				max-width: 100px;
			}
		}
		&__summary {
			width: 60%;
		}
	}
	
}



/* ============================================================ */
/* 取替用キッチン機器 共通  */
/* /product/kitchen01 */
/* ============================================================ */
.product_kitchen01 {
	.balloon {
		position: relative;
		margin: 20px 0 0;
		padding: 10px 15px;
		border: 2px solid $baseColor;
		color: $baseColor;
		font-size: $m;
		@include font-bold;
		@include radius(8);
		
		&::before,
		&::after {
			content: '';
			border-style: solid;
			border-width: 0 10px 12px;
			border-color: transparent;
			@include centering-elements(ture, false);
		}
		&::before {
			top: -12px;
			border-bottom-color: $baseColor;
		}
		&::after {
			top: -9px;
			border-bottom-color: $white;
		}
	}
	
}



/* ============================================================ */
/* 取替用キッチン機器　レンジフード　お手入れ簡単ノーリツのレンジフード */
/* /product/kitchen01/rangehood/oteire.html */
/* ============================================================ */
#product_kitchen01_rangehood_oteire {
	
	.icon-number {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		margin: 0 10px 0 0;
		background: #57c3e0;
		color: $white;
		@include radius(4);
		@include font-bold;
	}
}



/* ============================================================ */
/* キッチントップ */
/* /product/kitchen/ */
/* ============================================================ */
#product_kitchen_index {
	
	.kitchen-movie {
		position: relative;
		
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 20px;
			color: $white;
			font-size: $m;
			text-align: center;
			text-shadow: 0 0 15px rgba(#000, 0.3);
			line-height: 1.4;
		}
		&__ttl {
			font-size: $l;
		}
		&__icon {
			flex: 0 0 auto;
			margin: 15px 0;
			font-size: 4.5rem;
		}
		&__text {
			flex: 0 0 auto;
		}
		
	}
}



/* ============================================================ */
/* キッチン：レシピア プラス */
/* /product/kitchen/recipia_plus/ */
/* ============================================================ */
#product_kitchen_recipia_plus_index {
	
	.mylife {
		margin-left: -15px;
		margin-right: -15px;
		padding: 25px 0 0;
		background: url(/common/img/product/kitchen/recipia_plus/bg_mylife_sp.png) 0 0 no-repeat;
		background-size: 100% auto;
		
		&__body {
			margin: 0 30px;
		}
		&__content {
			padding: 25px 20px 30px;
			background: $white;
			border-top: 1px solid $borderColor;
			
			&:first-child {
				padding-top: 10px;
				border-top: none;
			}
			&:last-child {
				padding-bottom: 0;
			}
			&__link {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 0 0 15px;
				font-size: $m;
				line-height: 1.5;
				@include font-bold;
			}
		}
	}
	
}



/* ============================================================ */
/* キッチン：ジュ・テーム */
/* /product/kitchen/jtm/ */
/* ============================================================ */
#product_kitchen_jtm_index {
	.box--jtm {
		background: #fffcdb;
	}
}



/* ============================================================ */
/* バスルーム */
/* /product/bathroom/ */
/* ============================================================ */
.product_bathroom {
	
	.slick-dotted.slick-slider {
		margin-bottom: 20px;
	}
	.slick-prev,
	.slick-next {
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 22px;
		height: 22px;
		background: rgba($white, 0.8);
		color: $baseColor;
		font-size: $l;
		@include transition;
		
		&:hover {
			background: rgba($white, 0.8);
			color: $baseColor;
			@include opacity;
		}
		
		&::before {
			display: none;
		}
	}
	.slick-prev { left: 0; }	
	.slick-next { right: 0; }	
	
	.slick-dots {
		bottom: -20px;
		line-height: 0;
		
		li {
			width: 8px;
			height: 8px;
			margin: 0 4px;
			
			button {
				width: 8px;
				height: 8px;
				padding: 0;
				
				&::before {
					content: '';
					background: #979493;
					opacity: 1;
					@include circle(8);
				}
			}
			
			&.slick-active {
				button::before {
					background: $baseColor;
				}
			}
		}
	}
	
	.slick-slide {
		overflow: hidden;
		@include radius(8);
	}
	
	
	.equipment-set {
		border: 1px solid $borderColor;
		overflow: hidden;
		@include radius(8);
		
		.slick-slide {
			@include radius(0);
		}
	}
	
	
}



/* ============================================================ */
/* 洗面化粧台 共通 */
/* /product/sanitary/ */
/* ============================================================ */
.sanitary-plan {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	
	&__img {
		width: 36%;
		margin: 0 4% 20px 0;
	}
	&__label {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 60%;
		
		.label {
			width: 100%;
			height: 35px;
			margin: 0 0 10px;
			font-size: $xxxs;
			line-height: 1.3;
		}
	}
	&__text {
		width: 100%;
	}
}


/* ============================================================ */
/* 洗面化粧台：シャンピーヌ共通 */
/* /product/sanitary/shampine_new/ */
/* ============================================================ */
.product_sanitary_shampine_new {
	.eco-faucet {
		display: flex;
		flex-wrap: wrap;
		
		&__text {
			width: calc(100% - 30% - 10px);
		}
	}
}


/* ============================================================ */
/* 洗面化粧台：シャンピーヌ：オプションキャビネット */
/* /product/sanitary/shampine_new/cabinet02.html */
/* ============================================================ */
#product_sanitary_shampine_new_cabinet02 {
	.slick-dots {
		position: static;
		margin: 20px 0 0;
		text-align: left;
		
		li {
			width: 60px;
			margin: 0 15px 0 0;
			
			img {
				width: 100%;
			}
		}
	}
}



/* ============================================================ */
/* ミスト保湿浴のススメ 02：身体にやさしい入浴スタイル */
/* /product/onsui_danbou/mist_dryhot/susume/mist02.html */
/* ============================================================ */
#product_onsui_danbou_mist_dryhot_susume_mist02 {
	.box--green {
		background: #effbe4;
	}
}



/* ============================================================ */
/* ミスト保湿浴のススメ 07：おふろでエステ 2 */
/* /product/onsui_danbou/mist_dryhot/susume/mist07.html */
/* ============================================================ */
#product_onsui_danbou_mist_dryhot_susume_mist07 {
	.box--aroma {
		background: #e2ffc6;
	}
}


/* ============================================================ */
/* ACLEAD */
/* /product/bathroom/acleadseries/aclead.html */
/* ============================================================ */
#product_bathroom_acleadseries_aclead #ttl-shitsukan,
#product_bathroom_acleadseries_acleads #ttl-shitsukan {
	background: url(/common/img/product/bathroom/acleadseries/aclead/bg_shitsukan.png) right center no-repeat;
	background-size: contain;
}

#product_bathroom_acleadseries_aclead #ttl-kouzou,
#product_bathroom_acleadseries_acleads #ttl-kouzou {
	background: url(/common/img/product/bathroom/acleadseries/aclead/bg_kouzou.png) right center no-repeat;
	background-size: contain;
}

#product_bathroom_acleadseries_aclead #ttl-seinou,
#product_bathroom_acleadseries_acleads #ttl-seinou {
	background: url(/common/img/product/bathroom/acleadseries/aclead/bg_seinou.png) right center no-repeat;
	background-size: contain;
}


