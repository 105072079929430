@charset "utf-8";
/* ============================================================ */
/* 企業・IR情報 */
/* /company/ */
/* ============================================================ */




/* ============================================================ */
/* ニュースリリース トップ */
/* /company/news/ */
/* ============================================================ */
.company_news {
	.tab {
		flex-wrap: wrap;
		justify-content: center;
		margin-left: -5px;
		margin-right: -5px;
		
		&__item {
			width: calc((100% - 40px) / 4);
			margin: 0 5px;
		}
	}
}



/* ============================================================ */
/* 企業情報 */
/* /company/about/ */
/* ============================================================ */
#company_about_index {
	.topmessage {
		position: relative;
		display: block;
		color: $white;
		
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: flex-end;
			padding: 10px 20px;
			text-shadow: 0 0 2px rgba(#000, 0.4),  0 0 10px rgba(#000, 0.6);
			@include font-bold;
		}
		&__link {
			display: flex;
			align-items: center;
			font-size: $s;
		}
	}
}


/* ============================================================ */
/* 企業情報｜沿革 */
/* /company/about/history.html */
/* ============================================================ */
#company_about_history {
	
	.btn--accordion {
		&.active {
			.icon-add::before {
				content: "\e15b";
			}
		}
	}
	
	
	/* .history-anchorlink
	----------------------------------------------------------- */
	.history-anchorlink {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		overflow: hidden;
		
		&__item {
			position: relative;
			width: 18.2%;
			margin: 0 0 20px;
			padding: 0 4.4%;
			box-sizing: content-box;
			overflow: hidden;
			
			&::before {
				bottom: 10px;
				z-index: -1;
				content: '';
				width: 200px;
				border-top: 1px solid $borderColor;
				@include centering-elements(true, false);
			}
			&:first-child {
				margin-left: -4.4%;
				
				&::before { transform: translate(0, 0); }
			}
			&:nth-child(4) {
				margin-right: -4.4%;
				
				&::before {
					left: 0;
					transform: translate(0, 0);
				}
			}
			&:nth-child(5) {
				margin-left: -5.6%;
				padding-left: 10%;
			}
			
			&__link {
				display: flex;
				flex-direction: column;
				align-items: center;
				line-height: 1;
				
				.icon-chevron-circle-down {
					font-size: $xl;
				}
			}
			&__year {
				margin: 0 0 10px;
				color: $textColor;
				font-size: $l;
				@include font-bold;
			}
		}
	}
	
	
	/* .timeline-accordion
	----------------------------------------------------------- */
	.timeline-accordion {
		height: auto;
		overflow: hidden;
		@include transition;
		
		&.hide {
			height: 0;
		}
	} 
	
	
	
	/* .timeline
	----------------------------------------------------------- */
	.timeline {
		position: relative;
		margin: 0 0 45px;
		padding: 0 0 0 40px;
		
		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 12px;
			content: '';
			border-left: 2px solid $borderColor;
		}
		
		&__item {
			position: relative;
			margin: 0 0 45px;
			
			&::before {
				position: absolute;
				top: 0;
				left: -35px;
				display: block;
				content: '';
				background: $white;
				border: 4px solid $baseColor;
				@include circle(8);
			}
			
			&:last-child {
				margin-bottom: 0;
			}
			
			&__year {
				margin: 0 0 10px;
				font-size: $l;
				line-height: 1;
				@include font-bold;
			}
			&__content {
				margin: 0 0 25px;
				
				&:last-child {
					margin-bottom: 0;
				}

				&__img {
					width: 195px;
					margin: 15px 0 0 34px;
					border: 1px solid $borderColor;
					overflow: hidden;
					@include radius(8);
					
					& > * {
						max-width: 100%;
					}
				}
			}
		}
	}
	

	
}



/* ============================================================ */
/* 企業情報｜ブランドメッセージ */
/* /company/about/brandmessage.html */
/* ============================================================ */
#company_about_brandmessage {
	
	.img-zoom {
		position: relative;
		cursor: pointer;
		@include transition;
		
		&:hover {
			@include opacity;
		}
		&__icon {
			position: absolute;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 25px;
			height: 25px;
			background: $baseColor;
			color: $white;
		}
	}
	
	.special-box {
		display: block;
		margin: 0 0 20px;
		padding: 40px 15px;
		border: 1px solid $borderColor;
		color: $textColor;
		@include radius(8);

 		&__img {
			margin: 0 0 30px;
			text-align: center;
			
			img {
				max-width: 165px;
				width: 100%;
			}
		}
		
		&__body {
			display: flex;
			flex-direction: column;
		}

		&__ttl {
			margin: 0 0 15px;
			font-size: $l;
			line-height: 1.5;
			@include font-bold;
		}
		&__text {
			margin: 0 0 40px;
		}
		&__link {
			align-self: center;
			color: $textColor;
			font-size: $l;
			text-align: center;
			@include font-bold;
			
			.icon-circle-arrow-right {
				margin: 0 0 0 10px;
				color: $baseColor;
			}
		}
		&--ecorelakirei {
			background: url(/common/img/company/about/brandmessage/pic_ecorelakirei_sp.png) left bottom no-repeat;
			background-size: 100% auto;
			text-align: center;
			
			.special-box__link,
			.special-box__link .icon-circle-arrow-right {
				color: $white;
			}
		}
	}
}



/* ============================================================ */
/* 企業情報｜役員一覧 */
/* /company/about/outline/executive.html */
/* ============================================================ */
#company_about_outline_executive {
	.modal {
		.slick-prev {
			left: 0 !important;
		}
		.slick-next {
			right: 0 !important;
		}
		
		&__close-btn {
			top: 15px;
			right: 15px;
		}
	}
}



/* ============================================================ */
/* フォトライブラリー */
/* /company/about/sports/athletic/photolibrary/ */
/* /company/about/sports/fencing/photolibrary/ */
/* ============================================================ */
.company_about_sports {
	
	.photolibrary {
		&__item {
			line-height: 1.5;
			
			&__img {
				position: relative;
				margin: 0 0 15px;
				padding-top: calc(75% - 2px);
				border: 1px solid $borderColor;
				
				& > * {
					width: auto !important;
					height: auto;
					max-width: 100%;
					max-height: 100%;
					@include centering-elements(true, true);
				}
			}
			&__date {
				display: block;
				margin: 0 0 5px;
				color: $textColor;
				font-size: $xxs;
			}
			&__ttl {
				display: flex;
				align-items: center;
				span {
				}
				i.icon-keyboard_arrow_right.u-font-red {
				}
			}
		}
	}
	
	.img-zoom {
		position: relative;
		cursor: pointer;
		padding-top: calc(75% - 2px);
		border: 1px solid $borderColor;
		@include transition;

		&:hover {
			@include opacity;
		}
		&__icon {
			position: absolute;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			background: $baseColor;
			color: $white;
		}
		img {
			width: auto !important;
			max-width: 100%;
			max-height: 100%;
			@include centering-elements(true, true);
		}
	}

}


#company_about_sports_athletic_photolibrary_index,
#company_about_sports_fencing_photolibrary_index {
	.tab2 {
		&__item:first-child {
			.tab2__item__body {
				background: #e8380d;
				border-color: #e8380d;
				color: #fff;

				&::before {
					opacity: 1;
				}	
			}
		}
	}
}



/* ============================================================ */
/* 株主・投資家向け情報 共通 */
/* /company/ir/配下 */
/* ============================================================ */
.company_ir {
	.contents {
		margin-left: 0;
		margin-right: 0;
	}
	.lnav__ttl__link {
		&:hover {
			opacity: 1;
		}
		&::after {
			backface-visibility: visible;
		}
	}
	
	.js-tab {
		.js-tab_content {
			display: none;
		}
		& > .c-section + .js-tab_content {
			display: block;
		}
	}
}




/* ============================================================ */
/* 株主・投資家向け情報：業績・財務データ */
/* /company/ir/finance.html */
/* ============================================================ */
#company_ir_finance {
	
	.g-column_main_title {
		position: relative;
		margin: 60px 0 40px;
		padding-top: 40px;
		border-top: 1px solid #CCC;
		color: #e8380d;
		font-size: 64px;
		font-size: 4rem;
		text-align: center;
		
		&:first-child {
			margin-top: 0;
		}	
	}
	
	.xj_chart_table {
		border-top:1px solid gray;
		border-left:1px solid gray;
		border-collapse:collapse;
		border-spacing:0px;
	}
	.xj_chart_header_first {
		border-bottom:1px solid gray;
		border-right:1px solid gray;
	}
	.xj_chart_header {
		border-bottom:1px solid gray;
		border-right:1px solid gray;
		text-align:center;
		background-color:#D3D3D3;
	}
	.xj_chart_title {
		width:100px;
		font-size:10pt;
		border-bottom:1px solid gray;
		border-right:1px solid gray;
		background-color:#D3D3D3;
	}
	.xj_chart_unit {
		display:block;
	}
	.xj_chart_q {
		width:35px;
		border-bottom:1px solid gray;
		border-right:1px solid gray;
		text-align:right;
		background-color:#D3D3D3;
	}
	.xj_chart_q_last {
		width:35px;
		border-bottom:1px solid gray;
		border-right:1px solid gray;
		text-align:right;
		background-color:#D3D3D3;
	}
	.xj_chart_value {
		width:100px;
		border-bottom:1px solid gray;
		border-right:1px solid gray;
		text-align:right;
	}
	.xj_chart_value_last {
		width:100px;
		border-bottom:1px solid gray;
		border-right:1px solid gray;
		text-align:right;
	}
	.xj_chart_no_value {
		text-align:center;
	}
	
	.c-table .xj_chart_table {
		border: 1px solid #ccc;
		border-collapse: separate;
		border-radius: 8px;
		border-spacing: 0;
		overflow: hidden;
		table-layout: fixed;
		width: 100%;
	}
	.c-table .xj_chart_header_first,
	.c-table tr > .xj_chart_header {
		background-color: #f3f3f3;
		border-left: 1px solid #ccc;
		border-top: none;
		border-right:none;
		border-bottom:none;
		padding: 5px 0;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		word-break: break-all;
	}
	.c-table tr > .xj_chart_header:first-child {
		border-left:none;
		border-top: 1px solid #ccc;
	}
	.c-table .xj_chart_value {
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		border-right:none;
		border-bottom:none;
		padding: 10px 4px;
		vertical-align: middle;
		word-break: break-all;
	}
	.c-table .xj_chart_header_first:first-child,
	.c-table .xj_chart_value:first-child {
		border-left: none;
	}
	.c-table.noq .xj_chart_header_first,
	.c-table.noq tr > .xj_chart_header:first-child {
		display: none;
	}
	.c-table.noq tr > .xj_chart_header:nth-of-type(2),
	.c-table.noq tr > .xj_chart_value:nth-of-type(2) {
		border-left:none;
	}
	
	
}



/* ============================================================ */
/* 株主・投資家向け情報：株主情報：配当・株主還元 */
/* /company/ir/stock/dividend.html */
/* ============================================================ */
#company_ir_stock_dividend {
	.c-section_frame.xj-haito img {
		max-width: 100% !important;
		width: auto !important;
	}
	.xj_chart_value {
		text-align: right;
	}	
	.c-table .xj_chart_table {
		border: 1px solid #ccc;
		border-collapse: separate;
		border-radius: 8px;
		border-spacing: 0;
		overflow: hidden;
	}	
	.c-table.xj-haito .c-table_frame .xj_chart_table {
		table-layout: auto;
		width: 794px;
	}
	.c-table .xj_chart_header_first,
	.c-table tr > .xj_chart_header {
		padding: 5px 0;
		background-color: #f3f3f3;
		border-left: 1px solid #ccc;
		border-top: none;
		border-right: none;
		border-bottom: none;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
		word-break: break-all;
	}
	.c-table.xj-haito tr > .xj_chart_header:first-child,
	.c-table.xj-haito .xj_chart_value {
		background-color: #fff;
		background-color: #fff;
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		padding: 10px 4px;
		vertical-align: middle;
		word-break: break-all;
	}
	.c-table.xj-haito tr > .xj_chart_header:first-child,
	.c-table.xj-haito .xj_chart_header_first:first-child {
		border-left: none;
		width: 30%;
		text-align: left;
	}	
	
	
}



/* ============================================================ */
/* 株主・投資家向け情報：株主情報：資本金・発行済株式総数の推移 */
/* /company/ir/stock/transition.html */
/* ============================================================ */
#company_ir_stock_transition {
	.c-table {
		.c-table_head--border_left {
			border-left: 1px solid #ccc !important;
		}
		.c-table_head--text_center {
			text-align: center;
		}
		.c-table_data.c-table_data--border_left {
			border-left: 1px solid #ccc !important;
		}
	}
}



/* ============================================================ */
/* CSRの取り組み：国際的イニシアティブとの整合 */
/* /company/csr/initiative.html */
/* ============================================================ */
#company_csr_initiative {
	.shaded {
		background: url(/common/img/company/csr/initiative/line.svg) 0 0 no-repeat;
		background-size: 100% 100%;
	}
}



/* ============================================================ */
/* CSRの取り組み：経営とCSRの融合  マテリアリティ */
/* /company/csr/csrabout/materiality.html */
/* ============================================================ */
#company_csr_csrabout_materiality {
	.table {
		.bg-green { background: #cfe0c5; }
		.bg-green-lighten { background: #e8efe2; }
		.bg-pink { background: #f8e0d8; }
		.bg-pink-lighten { background: #fcf2ef; }
		.bg-yellow { background: #fbf7bf; }
		.bg-yellow-lighten { background: #fefde9; }
		.bg-purple { background: #dfd7fb; }
		.bg-purple-lighten { background: #ede8fd; }
		.th-bg-aqua { background: #9bdeff; }
		.th-bg-aqua-lighten { background: #caeeff; }
	}
}



/* ============================================================ */
/* CSRの取り組み：ガバナンス：リスクマネジメント */
/* /company/csr/governance/risk_management.html */
/* ============================================================ */
#company_csr_governance_risk_management {
	.box-naibutousei {
		background: url(/common/img/company/csr/governance/risk_management/bg_risk_management01.jpg) left bottom repeat-x #f5edd8;
		@include radius(8);
	}
	.ttl04 {
		&--pink { color:  #ff6666; }
		&--green { color:  #339966; }
		&--blue { color:  #3399cc; }
	}
}



/* ============================================================ */
/* CSRの取り組み：環境：生物多様性保全 */
/* /company/csr/kankyou/seibutu.html */
/* ============================================================ */
#company_csr_kankyou_seibutu {
	.product-wrap {
		background: url(/common/img/company/csr/kankyou/seibutu/img_torikumi01.png) 0 0 no-repeat;
		background-size: contain;
	}
}



/* ============================================================ */
/* CSRの取り組み：環境：製品を通じた環境負荷低減：森林カーボン・オフセット（2017） */
/* /company/csr/kankyou/seihin/forestoffsset.html */
/* ============================================================ */
#company_csr_kankyou_seihin_forestoffsset,
#company_csr_kankyou_seihin_forestoffset2017_01,
#company_csr_kankyou_seihin_forestoffset2017_02,
#company_csr_kankyou_seihin_forestoffset2017_03,
#company_csr_kankyou_seihin_forestoffset2017_04 {
	
	.forestoffsset-menu {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 25px;
		
		&__item {
			display: flex;
			width: 33%;
			border-right: 1px solid $forestoffset;
			border-bottom: 1px solid $forestoffset;

			&:nth-child(-n+3) {
				border-top: 1px solid $forestoffset;
			}
			&:nth-child(3n+1) {
				border-left: 1px solid $forestoffset;
			}
			
			&__link {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 10px;
				color: $forestoffset;
				text-align: center;
				line-height: 1.4;
				@include font-bold;
				
				&__text {
					margin: 5px 0 0;
				}
			}
		}
	}
	
	.sub_menu_title {
		margin: 0 0 15px;
		padding: 5px 15px;
		background: $forestoffset;
		color: $white;
		font-size: $m;
		@include font-bold;
	}
	.sub_menu_title2 {
		padding: 5px 0;
		background: #7fc116;
		color: $white;
		text-align: center;
		line-height: 1.5;
		@include font-bold;
	}
	.title_box,
	.title_box2,
	.title_box3 {
		margin: 15px 0;
		padding: 5px 15px;
		background: #f3fce4;
		border: 1px solid $forestoffset;
    color: #5c8910;
		@include font-bold;
	}
	
	.table01 {
		width: 100%;
		margin: 0 0 20px;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		background: $white;
		
		th,
		td {
			padding: 5px 10px;
			border-right: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}
		th {
			background: #efefef;
			color: #015f83;
			text-align: center;
			vertical-align: middle;
			white-space: nowrap;
		}
	}
	.table02 {
		width: 100%;
		margin: 0 0 20px;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		
		th,
		td {
			padding: 5px;
			border-right: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}
		
		th {
			background: #efefef;
			color: #015f83;
			text-align: center;
			vertical-align:middle;
			white-space: nowrap;
		}
	}
	
	.font-brown { color: #91700c; }
	.font-green { color: #167857; }
	.font-navy { color: #16678a; }
	
	.top_btn {
		width: 94%;
		margin: 10px auto;
		padding: 10px;
		background: #f08f05;
		border-radius: 5px;
		color: $white;
		text-align: center;
		line-height: 100%;
		@include font-bold;
	}	
	
	
}



/* ============================================================ */
/* ニュースリリース */
/* /company/news/ */
/* ============================================================ */
.company-news-entry {
	table:not(.table),
	table:not(.table) th,
	table:not(.table) td {
		border-style: solid;
		border-width: 1px;
	}
}