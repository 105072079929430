@charset "utf-8";
/* ============================================================ */
/* ハッピーパレット */
/* /happypalette/ */
/* ============================================================ */
#happypalette_index {
	
	.happypalette-contents {
		background: url(/common/img/happypalette/bg_main.gif) 0 0 repeat;
		text-align: center;

		h2 {
			padding: 0 0 40px;
		}
		h4 {
			color: #e15381;
			padding: 1em 0 0;
		}
		strong {
			color: #4b88b0;
			font-weight: normal;
		}
	}
	
	
	/*----- #main -----*/
	#main {
		padding: 70px 0 75px;
		
		ul {
			display: flex;
			width: 640px;
			margin: 0 auto;
			padding: 55px 0 0;
			
			li {
				width: 160px;
			}
		}
	}
	
	
	/*----- #experience -----*/
	#experience {
		position: relative;
		background: url(/common/img/happypalette/bg_experience.png) top center no-repeat;
		padding: 123px 0 0;
		
		.btn-happypalette {
			padding: 15px 0 10px;
		}
		&::before,
		&::after {
			position: absolute;
			top: 0;
			height: 123px;
			content: '';
			background: #e15381;
		}
		&::before {
			left: 0;
			right: calc(50% + 39px);
		}
		&::after {
			left: calc(50% + 39px);
			right: 0;
		}
		.experience-inner {
			padding: 20px 30px 50px;
			background: #e15381;
		}
	}
	
	
	/*----- #features -----*/
	#features {
		background: url(/common/img/happypalette/bg_features.gif) top center no-repeat #ee8b66;
		padding: 163px 0 50px;
		
		ul {
			display: flex;
			max-width: 920px;
			margin: 0 auto;
			
			li {
				width: 50%;
				padding: 0 30px;
			}
		}
	}
	
	
	/*----- #howto -----*/
	#howto {
		background: url(/common/img/happypalette/bg_howto.gif) top center no-repeat #e2ae50;
		padding: 163px 30px 50px;
		
		.btn-happypalette {
			padding: 15px 0 10px;
		}
	}
	
	
	/*----- #showroom -----*/
	#showroom {
		background: url(/common/img/happypalette/bg_showroom.gif) top center no-repeat #5ab180;
		padding: 163px 30px 50px;
		
		.btn-happypalette {
			padding: 40px 0;
		}
	}
	

	/* disclaimer
	----------------------------------------------- */
	.disclaimer {
		background: url(/common/img/happypalette/bg_disclaimer.gif) 0 0 repeat;
		padding: 40px;
		text-align: left;
	}
	
}