@charset "utf-8";

.label {
  white-space: nowrap;
}

.product {

	.picture-tab {
		display: flex;
		flex-direction: column-reverse;
		transition: opacity 0.3s;

		&__image {
			display: none;
			transition: opacity 0.3s;

			&:nth-child(1) {
				display: block;
			}
		}

		&__tabs {
			margin-top: 10px;

			&__item {
				opacity: .5;
				cursor: pointer;
				transition: all 0.3s;

				&.current {
					opacity: 1;
				}
			}
		}
	}
	
	/* .picture-nav
	----------------------------------------------------------- */

	.picture-nav-main {
		&__image {
			display: none;
			transition: all 0.3s;

			&:nth-child(1) {
				display: block;
			}
		}
	}

	.picture-nav {
		&__navs__item {
			cursor: pointer;
			transition: all 0.3s;

			&:hover {
				opacity: .7;
			}
		}

	}
	
	/* .pinch-out-icon
	----------------------------------------------------------- */
	
	.pinch-out-icon {
		position: relative;

		&__image {
			position: absolute;
			right: 0;
			bottom: 0;
			opacity: .5;
			
			&--left {
				left: 0;
			}

			img {
				width: 40px;
				height: 40px;
			}
		}
	}
}
