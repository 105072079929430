@charset "utf-8";
/* ============================================================ */
/* iconフォント */
/* ============================================================ */
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot?u43vqc');
  src:  url('/common/icon/fonts/icomoon.eot?u43vqc#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2?u43vqc') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf?u43vqc') format('truetype'),
    url('/common/icon/fonts/icomoon.woff?u43vqc') format('woff'),
    url('/common/icon/fonts/icomoon.svg?u43vqc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-caution:before {
  content: "\e93b";
}
.icon-faq:before {
  content: "\e93c";
}
.icon-square_phone:before {
  content: "\e938";
}
.icon-fax:before {
  content: "\e939";
}
.icon-internet:before {
  content: "\e93a";
}
.icon-play:before {
  content: "\e933";
}
.icon-pc:before {
  content: "\e932";
}
.icon-locker:before {
  content: "\e927";
}
.icon-drink:before {
  content: "\e928";
}
.icon-diapers:before {
  content: "\e929";
}
.icon-bear:before {
  content: "\e92a";
}
.icon-glasses:before {
  content: "\e92b";
}
.icon-wheelchair:before {
  content: "\e92c";
}
.icon-parking:before {
  content: "\e92d";
}
.icon-energy:before {
  content: "\e92e";
}
.icon-vanity:before {
  content: "\e92f";
}
.icon-system-kitchen:before {
  content: "\e930";
}
.icon-system-bath:before {
  content: "\e931";
}
.icon-mansion:before {
  content: "\e91f";
}
.icon-house:before {
  content: "\e920";
}
.icon-single:before {
  content: "\e921";
}
.icon-couple:before {
  content: "\e922";
}
.icon-family:before {
  content: "\e923";
}
.icon-oil:before {
  content: "\e924";
}
.icon-gas:before {
  content: "\e925";
}
.icon-electrical:before {
  content: "\e926";
}
.icon-heating:before {
  content: "\e916";
}
.icon-reheating:before {
  content: "\e917";
}
.icon-faucet:before {
  content: "\e91e";
}
.icon-bath:before {
  content: "\e918";
}
.icon-shower:before {
  content: "\e919";
}
.icon-document:before {
  content: "\e91a";
}
.icon-catalog2:before {
  content: "\e91b";
}
.icon-showroom:before {
  content: "\e91c";
}
.icon-question2:before {
  content: "\e91d";
}
.icon-goods:before {
  content: "\e90a";
}
.icon-inspection:before {
  content: "\e90b";
}
.icon-care:before {
  content: "\e90c";
}
.icon-product:before {
  content: "\e90d";
}
.icon-palette:before {
  content: "\e90e";
}
.icon-showroom-human:before {
  content: "\e90f";
}
.icon-showroom-check:before {
  content: "\e910";
}
.icon-repair2:before {
  content: "\e911";
}
.icon-download:before {
  content: "\e912";
}
.icon-showroom-find:before {
  content: "\e913";
}
.icon-catalog:before {
  content: "\e914";
}
.icon-online:before {
  content: "\e915";
}
.icon-ppt:before {
  content: "\e907";
}
.icon-excel:before {
  content: "\e908";
}
.icon-word:before {
  content: "\e909";
}
.icon-inquiry:before {
  content: "\e900";
}
.icon-trash:before {
  content: "\e901";
}
.icon-repair:before {
  content: "\e902";
}
.icon-chevron-circle-left:before {
  content: "\e934";
}
.icon-chevron-circle-right:before {
  content: "\e935";
}
.icon-chevron-circle-down:before {
  content: "\e936";
}
.icon-chevron-circle-up:before {
  content: "\e937";
}
.icon-circle-arrow-left:before {
  content: "\e903";
}
.icon-circle-arrow-right:before {
  content: "\e904";
}
.icon-circle-arrow-up:before {
  content: "\e905";
}
.icon-circle-arrow-down:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\f067";
}
.icon-question:before {
  content: "\f128";
}
.icon-minus:before {
  content: "\f068";
}
.icon-search2:before {
  content: "\f002";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close2:before {
  content: "\f00d";
}
.icon-remove2:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-volume-off:before {
  content: "\f026";
}
.icon-play2:before {
  content: "\f04b";
}
.icon-pause:before {
  content: "\f04c";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-feed:before {
  content: "\f09e";
}
.icon-rss:before {
  content: "\f09e";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-add:before {
  content: "\e145";
}
.icon-check_box:before {
  content: "\e834";
}
.icon-check_box_outline_blank:before {
  content: "\e835";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-not_interested:before {
  content: "\e033";
}
.icon-error:before {
  content: "\e000";
}
.icon-get_app:before {
  content: "\e884";
}
.icon-keyboard_arrow_down:before {
  content: "\e313";
}
.icon-keyboard_arrow_left:before {
  content: "\e314";
}
.icon-keyboard_arrow_right:before {
  content: "\e315";
}
.icon-keyboard_arrow_up:before {
  content: "\e316";
}
.icon-open_in_new:before {
  content: "\e89e";
}
.icon-live_help:before {
  content: "\e0c6";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-remove:before {
  content: "\e15b";
}
.icon-search:before {
  content: "\e8b6";
}
.icon-settings_phone:before {
  content: "\e8c5";
}
.icon-volume_mute:before {
  content: "\e04e";
}
.icon-volume_up:before {
  content: "\e050";
}
