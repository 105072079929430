@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.contents {
		max-width: none;
		margin: 0;
		padding: 0;
	}
	.row-wide {
		padding: 80px 0;

		&.bg-gray {
			background: #f3f3f3;
		}
		&.bg-red {
			background: #f55333;
		}
		&.bg-red-lighten {
			background: #feead6;
		}

		&__inner {
			max-width: 1160px;
			margin: 0 auto;
			padding: 0 20px;
		}
	}

	.index-ttl01,
	.index-ttl02,
	.index-ttl03 {
		color: $baseColor;
		text-align: center;
		line-height: 1.5;
	}

	.index-ttl01 {
		margin: 0 0 40px;
		font-size: 4rem;
	}
	.index-ttl02 {
		margin: 0 0 40px;
		font-size: 3.6rem;
	}
	.index-ttl03 {
		margin: 0 0 30px;
		font-size: $xxxl;
	}

	/* .index-visual
	----------------------------------------------------------- */
	.index-visual {
		position: relative;

		&::before {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 10;
			content: "";
			height: 40px;
			background: url(/common/img/index/bg_gray_wave.png) bottom center no-repeat;
			background-size: 100% 100%;
		}
		&__slider {
			opacity: 0;
			transition: opacity 0.3s linear;

			&.slick-initialized {
				opacity: 1;
			}
		}
		&__slider,
		&__slider * {
			backface-visibility: hidden;
		}
		&__scroll {
			bottom: 95px;
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: $white;
			@include centering-elements(true, false);
		}
		.slick-slide {
			position: relative;
			height: 42.6vw;
		}
		.slick-prev,
		.slick-next {
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35px;
			height: 67px;
			background: rgba($white, 0.8);
			color: $baseColor;
			font-size: 2.6rem;

			&::before {
				display: none;
			}
		}
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 0;
		}
		.slick-dots {
			bottom: 65px;
			pointer-events: none;

			li {
				width: 8px;
				height: 8px;
				margin: 0 3px;

				button {
					pointer-events: auto;
					padding: 0;
					background: rgba($white, 0.5);
					@include circle(8);

					&::before {
						display: none;
					}
				}

				&.slick-active {
					button {
						background: $white;
					}
				}
			}
		}
		.slick-dotted.slick-slider {
			margin-bottom: 0;
		}

		&__body {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			&__inner {
				position: relative;
				height: 100%;
				max-width: 1160px;
				margin: 0 auto;
				padding: 0 20px;
			}
		}
		&__btn-play,
		&__btn-volume {
			position: absolute;
			bottom: 65px;
			z-index: 10;
			background: none;
			border: 1px solid $white;
			color: $white;
			opacity: 0.6;
			cursor: pointer;
			@include circle(36);

			& > * {
				display: flex;
				align-items: center;
				justify-content: center;
				@include circle(34);
			}

			&:hover {
				opacity: 1;
			}
		}
		&__btn-play {
			right: 65px;

			&--play {
				.icon-pause::before {
					content: "\f04b";
				}
			}
		}
		&__btn-volume {
			right: 20px;
			font-size: $xl;

			&--off {
				.icon-volume_up::before {
					content: "\e04e";
				}
			}
		}

		.btn-cm {
			position: absolute;
			bottom: 130px;
			right: 170px;
			width: 210px;
			height: 50px;
			border: none;

			@media screen and (max-width: 1280px) {
				bottom: 100px;
			}
			@media screen and (max-width: 1024px) {
				bottom: 80px;
				right: 120px;
			}
		}

		&__slider {
			&__mv {
				background: #000;
				overflow: hidden;

				&__thumb {
					position: absolute;
					top: 0;
				}
				&__movie,
				&__thumb {
					margin-top: -5px;
				}
			}
		}
	}

	/* .important
	----------------------------------------------------------- */
	.important {
		display: flex;
		margin: 0 0 70px;

		&__ttl {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 170px;
			min-height: 64px;
			background: $baseColor;
			border: 1px solid $borderColor;
			border-radius: 8px 0 0 8px;
			color: $white;
			font-size: $m;
			@include font-bold;
		}
		&__body {
			width: calc(100% - 170px);
			min-height: 64px;
			background: $white;
			border: 1px solid $borderColor;
			border-left: none;
			border-radius: 0 8px 8px 0;
		}
		&__content {
			display: flex;
			padding: 20px 25px;

			&__link {
				display: flex;
				//				align-items: center;
				width: 100%;

				&__date {
					flex: 0 0 auto;
					width: 80px;
					font-size: $xxs;
				}
				&__text {
					flex: 1 1 auto;
				}
				.icon-angle-right {
					flex: 0 0 auto;
					align-self: center;
					margin: 0 0 0 20px;
					color: $baseColor;
				}
			}

			&:not(:first-child) {
				border-top: 1px solid $borderColor;
			}
		}
	}

	/* .pickup
	----------------------------------------------------------- */
	.pickup {
		display: flex;
		justify-content: space-between;
		margin: 0 -10px;

		&__item {
			width: calc((100% - 100px) / 5);
			max-width: 142px;
			margin: 0 10px;
			font-size: $l;
			text-align: center;

			&__link {
				img {
					@include transition;
				}
				&:hover {
					img {
						@include opacity;
					}
				}
			}
		}
	}

	/* .pickup-column
	----------------------------------------------------------- */
	.pickup-column {
		background: $white;
		border: 1px solid $borderColor;
		overflow: hidden;
		@include radius(8);
		@include font-form;

		img {
			@include transition;
		}

		&__body {
			padding: 20px 25px;
			border-top: 1px solid $borderColor;
		}
		&:hover {
			img {
				@include opacity;
			}
		}
		.u-font-bold {
			font-weight: bold;
			@include font-form;
		}
	}

	/* .product
	----------------------------------------------------------- */
	.product {
		position: relative;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			content: "";
			height: 40px;
			background: url(/common/img/index/bg_orange_wave.png) top center no-repeat $white;
			background-size: cover;
		}

		&__column {
			display: flex;
			justify-content: space-between;

			&__item {
				width: 40.6%;

				&--lg {
					width: 57.6%;
				}
				&__link {
					position: relative;
					display: block;

					& > * {
						position: relative;
						z-index: 1;
					}
					img {
						@include transition;
					}

					&:hover {
						opacity: 1;

						img {
							@include opacity;
						}
					}
					&::before {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 0;
						content: "";
						background: $white;
						@include radius(9);
					}
				}
			}
		}
	}

	/* .product02
	----------------------------------------------------------- */
	.product02 {
		background: url(/common/img/index/bg_cta.png) top center no-repeat;
		background-size: cover;

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 266px;
			background: rgba($white, 0.8);
			color: $textColor;
			font-size: $xxxl;
			@include radius(8);
			@include font-bold;

			&__icon {
				margin: 0 0 15px;
			}
		}
	}

	/* .product-notice
	----------------------------------------------------------- */
	.product-notice {
		&__ttl {
			position: relative;

			.index-ttl02 {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&__list {
				position: absolute;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
			}
		}
	}

	/* .news
	----------------------------------------------------------- */
	.news {
		&__ttl {
			position: relative;

			&__link {
				position: absolute;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		.news-list {
			.label {
				background: rgba($white, 0.6);
			}
		}
	}

	/* .sns-official
	----------------------------------------------------------- */
	.sns-official {
		display: flex;
		align-items: center;
		justify-content: center;

		&__item {
			width: 222px;
			margin: 0 10px;
		}
	}

	/* .bnr-slider
	----------------------------------------------------------- */
	.bnr-slider {
		padding: 0 54px;

		&__item {
			padding: 0 8px;

			a {
				display: block;
			}
		}

		.slick-prev,
		.slick-next {
			width: 35px;
			height: 67px;
			border: 1px solid $baseColor;
			color: $baseColor;
			font-size: 2.6rem;

			&__inner {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 33px;
				height: 65px;
			}
			&::before {
				display: none;
				content: "";
			}
		}
		.slick-prev {
			left: 0;
		}
		.slick-next {
			right: 0;
		}
	}

	/* .YouYube MV 2021.06
	----------------------------------------------------------- */

	#ytContainer {
		height: 42.6vw;
		overflow: hidden;
		position: relative;
		> .ytScreen {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			cursor:pointer;
		}
	}

	#yt70th {
		width: 100vw;
		height: 56.25vw;
		transform: translateY(-6.825vw);
	}

	#ytModal {
		position: fixed;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(255, 255, 255, 0.9);
		top: 0;
		left: 0;
		z-index: 11;
		transition: opacity 0.5s;
		&:not(.active) {
			opacity: 0;
			pointer-events: none;
		}
		&.active {
			opacity: 1;
			pointer-events: auto;
		}
		iframe {
			width: 60vw;
			height: 33.75vw;
			margin-bottom:60px;
		}
	}

	.ytClose {
		display: block;
		width: 40px;
		height: 40px;
		position: relative;
		border:none;
		background:transparent;
		margin:0 0 20px calc( 60vw - 40px );
		cursor:pointer;
		&::before,
		&::after {
			content:'';
			display:block;
			width:32px;
			height:2px;
			background:#000;
			position:absolute;
			top:19px;
			left:4px;
		}
		&::before{
			transform:rotate(45deg);
		}
		&::after{
			transform:rotate(-45deg);
		}
	}
}
