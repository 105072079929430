@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	order: -1;
	margin: 35px 15px 80px;
}

.contents-lower {
	margin: 0 15px 60px;
}


.row {
	margin-bottom: 60px;
	
	&--wide {
		margin-left: 15px;
		margin-right: 15px;
	}
	&--bg-gray {
		background: #f3f3f3;
	}
	&--bg-red {
		background: #feead6;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.radius,
.sp-radius {
	@include radius(8);
}

.img-border {
	border: 1px solid $borderColor;
}

.ellipsis {
	position: relative;
	height: calc(1em * 1.8 * 2);
	text-align: justify;
	text-justify: inter-ideograph;
	word-break: break-all;
	overflow: hidden;
	
	&:before,
	&:after{
		position: absolute;
		background: $white;
	}
	&:before{
		top: calc(1em * 1.8 * (2 - 1));
		right: 0;
		content: '...';
		width: 2em;
		text-align: center;
	}
	&:after {
		content: '';
		height: 100%;
		width: 100%;
	}
}



/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	margin: 0 0 20px;
	font-size: $xl;
	line-height: 1.4;
	@include font-bold;
	
	&__sub {
		display: block;
		margin: 5px 0 0;
		font-size: $s;
	}
}

.ttl02 {
	margin: 0 0 20px;
	padding: 0 0 0 20px;
	border-left: 3px solid $baseColor;
	font-size: $xl;
	line-height: 1.4;
	@include font-bold;
}

.ttl03 {
	margin: 0 0 20px;
	padding: 0 0 0 20px;
	border-left: 3px solid #f39b86;
	color: #333;
	font-size: $l;
	line-height: 1.4;
	@include font-bold;
	
	&.sp-accordion {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		
		.icon-add,
		.icon-remove {
			flex: 0 0 auto;
			font-size: $xl;
		}
		&.active {
			.icon-remove::before {
				content: "\e145";
			}
		}
	}
}

.ttl04 {
	margin: 0 0 15px;
	font-size: $m;
	line-height: 1.4;
	@include font-bold;
}

.ttl05 {
	color: $baseColor;
	font-size: 2.6rem;
	line-height: 1.4;
}

.ttl06 {
	font-size: $xl;
	text-align: center;
	line-height: 1.4;
	@include font-bold;
}



/* --------------------------------------------------- */
/* .visual */
/* --------------------------------------------------- */
.visual {
	margin: 0 0 45px;
	overflow: hidden;
	
	.slick-dotted.slick-slider {
		margin-bottom: 20px;
	}
	.slick-prev,
	.slick-next {
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 22px;
		height: 22px;
		background: rgba($white, 0.8);
		color: $baseColor;
		font-size: $l;
		@include transition;
		
		&:hover {
			background: rgba($white, 0.8);
			color: $baseColor;
			@include opacity;
		}
		
		&::before {
			display: none;
		}
	}
	.slick-prev { left: 0; }	
	.slick-next { right: 0; }	
	
	.slick-dots {
		bottom: -20px;
		line-height: 0;
		
		li {
			width: 8px;
			height: 8px;
			margin: 0 4px;
			
			button {
				width: 8px;
				height: 8px;
				padding: 0;
				
				&::before {
					content: '';
					background: #979493;
					opacity: 1;
					@include circle(8);
				}
			}
			
			&.slick-active {
				button::before {
					background: $baseColor;
				}
			}
		}
	}
	
	.slick-slide {
		overflow: hidden;
		@include radius(8);
	}
}


.visual02 {
	margin-left: -15px;
	margin-right: -15px;
	
	&__img {
		display: block;
		margin: 0 0 30px;
	}
	&__lead {
		margin: 0 15px;
		text-align: center;
	}
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -2%;
	margin-right: -2%;

	&> * {
		margin-left: 2%;
		margin-right: 2%;
		width: 46%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 14.6%;
	}
}

.column7 {
	margin-left: -0.5%;
	margin-right: -0.5%;

	&> * {
		margin-left: 0.5%;
		margin-right: 0.5%;
		width: 13.28%;
	}
}




.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -2%;
	margin-right: -2%;

	&> * {
		margin-left: 2%;
		margin-right: 2%;
		width: 46%;
	}
	.sp-col2 {
		width: calc(100% - 4%);
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.8em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 3px;
				height: 3px;
				border-radius: 50%;
			}
		}
	}

	&--gray {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--red {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;
	background: $white;
	@include radius(8);
	
	&--gray {
		background: #f3f3f3;
	}
	&--border-gray {
		border: 1px solid $borderColor;
	}
	&--border-red {
		border: 1px solid $baseColor;
	}
	&--border-purple {
		border: 1px solid $purple;
	}
	&--orange {
		background: #feead6;
	}
	&--lightorange {
		background: #fcf1e6;
	}
	&--lightgray {
		background: #fbfbfb;
	}
	&--accordion {
		padding: 0;
		border: 1px solid $borderColor;
		
		&__ttl {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 15px;
			background: rgba($baseColor, .1);
			line-height: 1.5;
			@include font-bold;
			
			.icon-add {
				font-size: $xl;
			}
			&.active {
				.icon-add::before { content: "\e15b"; }
			}
		}
		&__body {
			padding: 15px;
		}
	}
	
	&__band {
		margin: 0 -15px;
		padding: 10px 15px;
		max-width: none;
		
		&--gray {
			background: #f3f3f3;
		}
		&--red {
			background: $baseColor;
			color: $white;
		}
	}
}

.box-account {
	margin: 0 -15px;
	padding: 30px 15px;
	border-top: 2px solid #e6e6e6;
	border-bottom: 2px solid #e6e6e6;
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	border: 1px solid $borderColor;
	border-collapse: separate;
	border-spacing: 0;
	@include radius(8);
	
	th,
	td {
		padding: 10px 12px;
		background: $white;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	th {
		background: #f3f3f3;
		@include font-bold;
	}
	th,td {
		+th,+td {
		  border-left: 1px solid $borderColor;
		}
	}
	tr+tr {
		th,td{
		border-top: 1px solid $borderColor;
	  	}
	}
	&--thin,
	&--thin2 {
		th,
		td {
			padding: 10px;
			font-size: $xxs;
		}
	}
	.bg-white {
		background: $white;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.bg-darkgray {
		background: #ebebeb;
	}
	.bg-red {
		background: #feead6;
	}
	.bdr-left {
		border-left: 1px solid $borderColor !important;
	}
	.bdr-radius {
		border-bottom-left-radius: 8px !important;
	}
	.bdr-radius-none {
		border-bottom-left-radius: 0 !important;
	}
	
	tr > *:first-child {
		border-left-width: 0;
	}
	thead {
		tr:first-child {
			& > *:first-child {
				border-top-left-radius: 8px;
			}
			& > *:last-child {
				border-top-right-radius: 8px;
			}
		}
	}
	tbody {
		tr:last-child {
			& > * {
				border-bottom-width: 0;

				&:first-child {
					border-bottom-left-radius: 8px;
				}
				&:last-child {
					border-bottom-right-radius: 8px;
				}
			}
		}
		
		&:first-child {
			tr:first-child {
				& > *:first-child {
					border-top-left-radius: 8px;
				}
				& > *:last-child {
					border-top-right-radius: 8px;
				}
			}
		}
	}
	
	
	&--sp-column1 {
		display: block;
		border-top: none;
		
		thead,
		tbody,
		tr {
			display: block;
		}
		th,
		td {
			display: block;
			padding: 15px;
			border: none;
			border-top: 1px solid $borderColor;
		}
		tbody {
			tr:last-child {
				& > * {
					&:first-child {
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
					&:last-child {
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					}
				}
			}
			
			&:first-child {
				tr:first-child {
					& > *:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
					& > *:last-child {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}
				}
			}
		}
		
	}
	
	
}

.pinned {
	border-right: none !important;
	border-radius: 8px 0 0 8px;
	
	.table {
		border-radius: 8px 0 0 8px;
	}
}
.table-wrapper {
	border-right: none !important;
	
	.scrollable {
		.table {
			border-left: none !important;
			border-radius: 0 8px 8px 0;
		}
		
	}
}
.table-scroll {
	overflow-x: auto;
	
	th,
	td {
		white-space: nowrap;
	}
}



/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 60px;
	padding: 15px 25px;
	background: $white;
	border: 1px solid $borderColor;
	color: $textColor;
	text-align: center;
	line-height: 1.6;
	@include radius(8);
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	[class*="icon-angle-"],
	[class*="icon-keyboard_arrow_"] {
		font-size: $xl;
	}
	
	&:disabled {
		opacity: 0.5;
		cursor: auto;
	}
	
	&--xs {
		max-width: 200px;
		min-height: 0;
		height: 35px;
		font-size: $xxs;
		@include radius(4);
	}
	&--orange {
		background: #feead6;
		
		&:hover {
			background: #feead6;
			border-color: $borderColor;
			color: $textColor;
			@include opacity;
		}
	}
	
	&.accordion {
		&.active {
			.icon-add::before {
				content: "\e15b";
			}
		}
	}
	
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

.btn-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	
	.btn {
		margin-bottom: 12px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	&.u-sp-fxd-cr {
		.btn {
			margin-bottom: 12px;
			
			&:first-child {
				margin-bottom: 0;
			}
		}
	}
	
	&--sp-column2 {
		flex-direction: row;
		justify-content: center;
		
		.btn {
			margin: 0 5px;
		}
	}
}


/* --------------------------------------------------- */
/* related-link */
/* --------------------------------------------------- */
.related-link {
	&__item {
		color: $textColor;
		
		&__icon {
			display: none;
		}
		[class*="icon-keyboard_arrow_"] {
			position: relative;
			top: 3px;
			font-size: $l;
		}
	}
}

/* --------------------------------------------------- */
/* menu-btn */
/* --------------------------------------------------- */
.menu-btn {
	position: relative;
	display: flex;
	align-items: center;
	height: 70px;
	padding: 0 0 0 15px;
	background: $white;
	border: 1px solid $borderColor;
	color: $textColor;
	font-size: $m;
	line-height: 1.5;
	@include radius(8);
	
	.icon-fix-left,
	.icon-fix-right {
		@include centering-elements(false, true);
	}
	.icon-fix-left { left: 10px; }
	.icon-fix-right { right: 10px; }
	
	[class*="icon-keyboard_arrow_"] {
		font-size: $xl;
	}
	
	&__body {
		display: flex;
		align-items: center;
	}
	&__icon {
		flex: 0 0 auto;
		width: 40px;
		margin: 0 15px 0 0;
	}
	
	&--sm {
		.menu-btn__icon {
			color: $baseColor;
			font-size: 3.4rem;
			text-align: center;
		}
	}
	&--lg {
		justify-content: center;
		height: 100px;
		padding: 0;
		font-size: $xxs;
		@include font-bold;
		
		.menu-btn__body {
			flex-direction: column;
			text-align: center;
		}
		.menu-btn__icon {
			width: auto;
			margin: 0;
			color: $baseColor;
			font-size: 3.6rem;
		}
		
	}
}


/* --------------------------------------------------- */
/* memu-box */
/* --------------------------------------------------- */
.memu-box {
	margin-bottom: 40px;
	
	&__thumb,
	&__thumb > * {
		@include radius(8);
	}
	&__thumb {
		margin: 0 0 15px;
	}
	&__body {
		margin: 0 10px;
	}
	&__ttl {
		margin-bottom: 10px;
		@include font-bold;
	}
}


/* --------------------------------------------------- */
/* menu-sp-accordion */
/* --------------------------------------------------- */
.menu-sp-accordion {
	padding: 0 15px;
	border: 1px solid $borderColor;
	border-top: none;
	border-radius: 0 0 8px 8px;
	
	&__ttl {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 0;
		
		.icon-add {
			font-size: $xl;
		}
		&.active {
			.icon-add::before { content: "\e15b";	}
		}
	}
	&__body {
		padding: 15px 0;
		border-top: 1px solid $borderColor;
	}
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 12px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			text-decoration: none;
			@include radius(3);
			
			&.current {
				background: $baseColor;
				border: 1px solid $baseColor;
				color: #fff;
				opacity: 1;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 3px 8px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	line-height: 1.2;
	@include radius(4);
	@include centering-elements(false, true);
}

.error {
	margin: 5px 0 0;
	color: $baseColor;
	font-size: $xxs;
}

.hankaku {
	ime-mode: disabled;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="password"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
	@include font-form;
}
input::-webkit-input-placeholder { color: $borderColor; }
input:-moz-placeholder { color: $borderColor; }
input::-moz-placeholder { color: $borderColor; }
input:-ms-input-placeholder { color: $borderColor; }

input:focus::-webkit-input-placeholder { color: transparent; }
input:focus:-moz-placeholder { color: transparent; }
input:focus::-moz-placeholder { color: transparent; }
input:focus::-ms-input-placeholder { color: transparent; }

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
	@include font-form;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 20px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: $white;
			border: 1px solid $textColor;
			@include circle(14);
		}
		&::after {
			left: 4px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::before {
			border-color: $baseColor;
		}
		&::after {
			opacity: 1;
		}
	}
}


/* radio-btn
----------------------------------------------------------- */
.radio-btn {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	
	input[type="radio"] + .radio-btn__body {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 125px;
		padding: 10px 15px;
		background: $white;
		border: 1px solid $borderColor;
		font-size: $m;
		text-align: center;
		@include radius(8);
		@include font-bold;
		
		.radio-btn__icon {
			margin: 0 0 5px;
			color: $baseColor;
			font-size: 4.2rem;
			text-align: center;
			line-height: 1;
		}
		.icon-keyboard_arrow_right {
			right: 15px;
			font-size: $l;
			@include centering-elements(false, true);
		}
	}
	input[type="radio"]:checked + .radio-btn__body {
		border: 3px solid $baseColor;
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 25px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 16px;
			height: 16px;
			background: $white;
			border: 1px solid #b0b0b0;
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::before {
			border-color: $baseColor;
		}
		&::after {
			opacity: 1;
		}
	}
	
	&--border-red {
		input[type="checkbox"] + span {
			&::before {
				border: 2px solid $baseColor;
				@include radius(4);
			}
			&::after {
				left: 2px;
			}
		}
	}
}


/* checkbox-btn
----------------------------------------------------------- */
.checkbox-btn {
	position: relative;
	
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + .checkbox-btn__body  {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 70px;
		padding: 0 0 0 30px;
		background: $white;
		color: $textColor;
		font-size: $m;
		cursor: pointer;
		@include radius(8);
		@include font-bold;
		@include transition;
		
		&::before,
		&::after {
			position: absolute;
			content: '';
		}
		&::before {
			top: 1px;
			bottom: 1px;
			left: 1px;
			width: 30px;
			border-radius: 8px 0 0 8px;
			background: $orange;
		}
		&::after {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 1px solid $borderColor;
			@include radius(8);
		}
		
		.checkbox-btn__check {
			&::after {
				left: 6px;
				content: "\e835";
				color: $white;
				font-family: 'icomoon';
				font-size: $xl;
				@include centering-elements(false, true);
			}
		}
	}
	input[type="checkbox"]:checked + .checkbox-btn__body {
		&::after {
			border: 1px solid $baseColor;
		}
		
		.checkbox-btn__check {
			&::after {
				content: "\e834";
			}
		}
	}
	
	input[type="checkbox"]:disabled + .checkbox-btn__body {
		padding: 0;
		
		&::before {
			display: none;
		}
		&:hover {
			&::after {
				border: 1px solid $borderColor;
			}
		}
		
		.checkbox-btn__check {
			&::after {
				display: none;
			}
		}
	}
	
}


/* selectbox
----------------------------------------------------------- */
.selectbox {
	position: relative;
	height: 35px;
	background: $white;
	border: 1px solid $borderColor;
	cursor: pointer;
	
	&::after {
		right: 0;
		width: 30px;
		content: "\e313";
		font-family: 'icomoon';
		color: $baseColor;
		font-size: $xxl;
		text-align: center;
		pointer-events: none;
		@include centering-elements(false, true);
	}
	
	select {
		width: 100%;
		height: 33px;
		padding: 0 10px;
		background: $white;
		font-size: $m;
		cursor: pointer;
		appearance: none;
		
		&:disabled {
			color: $textColor;
		}
	}
	select::-ms-expand {
		display: none;
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 17px;
	border: 1px solid #d8d8d8;
	font-size: $xxs;
	text-align: center;
	
	&--important {
		background: $baseColor;
		border-color: $baseColor;
		color: $white;
	}
	&--radius {
		display: inline-flex;
		width: auto;
		min-width: 70px;
		height: 20px;;
		padding: 0 10px;
		font-size: $xxs;
		border: none;
		@include radius(8);
	}
	&--radius-lg {
		display: inline-flex;
		width: auto;
		height: 32px;;
		padding: 0 15px;
		font-size: $s;
		border: none;
		@include radius(50);
	}
	&--gray {
		background: #f3f3f3;
	}
	&--border-red {
		border: 1px solid $baseColor;
		color: $baseColor;
	}
	&--red {
		background: $baseColor;
		color: $white;
	}
	&--red-lighten {
		background: #feead6;
	}
	&--red-lighten02 {
		background: #ef7456;
		color: $white;
	}
	&--blue-lighten {
		background: #7aacd8;
		color: $white;
	}
}

.label-feature {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 35px;
	border-radius: 100px;
	font-size: $xxxs;
	text-align: center;
	line-height: 1.4;
	
	&--on {
		background: #feead6;
	}
	&--off {
		background: #ebebeb;
		color: rgba($textColor, 0.3);
	}
}



/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	border-top: 1px solid $borderColor;
	
	&__item {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 15px 40px 15px 0;
		border-bottom: 1px solid $borderColor;
		
		&__date {
			margin: 0 15px 0 0;
			font-size: $xxs;
		}
		&__category {
			flex: 0 0 auto;
		}
		&__text {
			width: 100%;
			margin: 5px 0 0;
		}
		&__link {
			color: $textColor;
		}
		
		.icon-keyboard_arrow_right {
			right: 0;
			color: $baseColor;
			font-size: $l;
			@include centering-elements(false, true);
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	p {
		margin-bottom: 0;
	}
}



/* --------------------------------------------------- */
/* link-movie */
/* --------------------------------------------------- */
.link-movie {
	position: relative;
	display: block;
	
	&__icon {
		color: $white;
		font-size: 4.5rem;
		text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
		@include centering-elements(true, true);
	}
}



/* --------------------------------------------------- */
/* contact-center */
/* --------------------------------------------------- */
.contact-center {
	&__phone {
		text-align: center;
		
		&__ttl {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $l;
			@include font-bold;
			
			.icon-settings_phone {
				margin: 0 5px 0 0;
				color: $baseColor;
				font-size: 2.6rem;
			}
		}
		&__number {
			color: $baseColor;
			font-size: 3.4rem;
			letter-spacing: -0.02em;
			@include font-bold;
			
			&--small {
				font-size: 3.0rem;
			}
		}
		&__charge {
			margin: 0 0 10px;
			border: 1px solid $textColor;
			font-size: $xxs;
			@include radius(50);
		}
		&__mobile {
			font-size: $xxs;
			line-height: 1.4;
			
			&__number {
				margin: 0 0 0 5px;
				color: $textColor;
				font-size: $xxl;
				letter-spacing: -0.02em;
				@include font-bold;
			}
		}
	}
	&__reception {
		margin: 15px 0 0;
		
		&__time {
			margin: 0 -15px 15px;
			padding: 10px;
			background: #f3f3f3;
			text-align: center;
		}
	}
}


/* --------------------------------------------------- */
/* anchor-link */
/* --------------------------------------------------- */
.anchor-link {
	display: flex;
	flex-wrap: wrap;
	padding: 15px 0;
	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	line-height: 1.5;
	
	&__item {
		display: flex;
		width: 50%;
		margin: 2px 0;
		
		&__link {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 5px 15px;
			color: $textColor;
			
			.icon-keyboard_arrow_down {
				font-size: $l;
			}
		}
	}
}


/* --------------------------------------------------- */
/* lnav */
/* --------------------------------------------------- */
.contents {
	.lnav {
		margin: -35px -15px 25px;
		line-height: 1.5;

		&__ttl {
			&__link {
				display: flex;
				align-items: center;
				padding: 15px 10px 15px 15px;
				background: #f55333;
				color: $white;
				font-size: $xxl;
				
				&::after {
					content: "\e313";
					margin-left: auto;
					font-family: 'icomoon';
					font-size: 3.0rem;
					@include transition;
				}
				&.active {
					&::after {
						transform: rotateX(180deg);
					}
				}
			}
		}
		&__level1 {
			display: none;
			
			&__item {
				border-bottom: 1px solid $borderColor;

				&__link {
					display: flex;
					align-items: center;
					padding: 15px;
					background: #feead6;
					color: $textColor;
					
					[class^="icon-angle-"] {
						margin-left: auto;
						color: $baseColor;
						font-size: $xl;
					}
				}
			}
		}
		
		&__level2 {
			&__item {
				border-top: 1px solid $borderColor;
				
				&__link {
					display: flex;
					align-items: center;
					padding: 15px 15px 15px 30px;
					color: $textColor;
					
					[class^="icon-angle-"] {
						margin-left: auto;
						color: $baseColor;
						font-size: $m;
					}
				}
			}
		}
	}
}


/* --------------------------------------------------- */
/* snav */
/* --------------------------------------------------- */
.snav {
	&__ttl {
		border: 1px solid $borderColor;
		border-radius: 8px 8px 0 0;
		
		&__link {
			display: flex;
			align-items: center;
			height: 60px;
			padding: 0 0.83em;
			color: $textColor;
			font-size: $l;
			
			&__text {
				flex: 1 1 auto;
			}
			&:hover {
				background: #feead6;
				color: $baseColor;
				opacity: 1;
			}
		}
	}
	&__body {
		border: 1px solid $borderColor;
		border-top: none;
		border-radius: 0 0 8px 8px;
	}
	&__item {
		&:not(:last-child) {
			border-bottom: 1px solid $borderColor;
		}
		&__link {
			display: flex;
			align-items: center;
			height: 50px;
			padding: 0 1.1em;
			color: $textColor;
			
			&__text {
				flex: 1 1 auto;
			}
			.icon-keyboard_arrow_right {
				font-size: $xxl;
			}
			&:hover {
				color: $baseColor;
				opacity: 1;
			}
			&--current {
				background: #feead6;
			}
		}
	}
}


/* --------------------------------------------------- */
/* progress */
/* --------------------------------------------------- */
.progress {
	display: flex;
	
	&__item {
		flex: 1 1 auto;
		position: relative;
		display: flex;
		padding: 0 20px 0 0;
		
		&__text {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			min-height: 35px;
			padding: 8px 0 8px 10px;
			background: #f3f3f3;
			border-radius: 8px 0 0 8px;
			text-align: center;
			line-height: 1.3;
		}
		&::after {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 2px;
			content: '';
			width: 20px;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-image: url(/common/img/common/bg_progress_arrow_gray.svg);
			background-size: 20px 100%;
		}
		
		&:last-child {
			padding-right: 0;
			
			&:after {
				display: none;
			}
			.progress__item__text {
				padding-left: 0;
				@include radius(8);
			}
		}
		
		&--current {
			.progress__item__text {
				background: $baseColor;
				color: $white;
			}
			&::after {
				background-image: url(/common/img/common/bg_progress_arrow_red.svg);
			}
		}
	}
	
	&--sp-column2 {
		flex-wrap: wrap;
		
		.progress__item {
			width: 50%;
			margin-bottom: 10px;
		}
	}
}


/* --------------------------------------------------- */
/* progress-step */
/* --------------------------------------------------- */
.progress-step {
	&__item {
		&::after {
			display: block;
			content: '';
			width: 100%;
			height: 38px;
			background: url(/common/img/common/bg_progress_step_arrow_sp.svg) center bottom no-repeat;
			background-size: 100% auto;
		}
		&__body {
			display: flex;
			flex-direction: column;
			border: 1px solid $baseColor;
			border-bottom: none;
			border-radius: 8px 8px 0 0;
		}
		&__step {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 15px;
			padding: 5px 10px;
			background: $baseColor;
			color: $white;
			border-radius: 8px 8px 0 0;
			@include font-bold;
			
			&__text {
				margin-right: 10px;
			}
		}
		&__text {
			align-self: center;
			margin-bottom: -5px;
		}
	}
}


/* --------------------------------------------------- */
/* agreement */
/* --------------------------------------------------- */
.agreement {
	padding: 15px;
	border: 1px solid $borderColor;
	overflow-y: auto;
}


/* --------------------------------------------------- */
/* panel-link */
/* --------------------------------------------------- */
.panel-link {
	position: relative;
	display: block;
	
	&__ttl {
		display: flex;
		align-items: center;
		left: 15px;
		color: $white;
		font-size: $l;
		text-shadow: 0 0 15px rgba(#000, 0.3);
		line-height: 1.4;
		@include centering-elements(false, true);
	}
}


/* --------------------------------------------------- */
/* lineup */
/* --------------------------------------------------- */
.lineup {
	margin-bottom: 40px;
	
	&__thumb {
		display: block;
		margin: 0 0 20px;
		border: 1px solid $borderColor;
		@include radius(8);
	}
	&__ttl {
		margin: 0 10px 10px;
		@include font-bold;
	}
	&__text {
		margin: 0 10px;
	}
}

.lineup02 {
	display: flex;
	margin: 0 0 20px;
	border: 1px solid $borderColor;
	@include radius(8);
	
	&__thumb {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		padding: 15px;
	}
	&__legend {
		display: flex;
		flex-wrap: wrap;
		width: 50%;
		
		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 50%;
			min-height: 85px;
			border-left: 1px solid $borderColor;
			color: #d2d2d2;
			font-size: 1.1rem;
			@include font-bold;
			
			&:nth-child(n+3) {
				border-top: 1px solid $borderColor;
			}
			&__icon {
				margin: 0 0 3px;
				font-size: 4.8rem;
			}
			
			&--support {
				color: $textColor;
				
				.lineup02__legend__item__icon { color: $orange; }
			}
		}
	}
}

.lineup03 {
	border: 1px solid $borderColor;
	@include radius(8);
	
	&__ttl {
		padding: 10px 20px;
		border-bottom: 1px solid $borderColor;
	}
	&__body {
		display: flex;
		padding: 15px 15px 15px 10px;
	}
	&__thumb {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32%;
		
		& > * {
			max-width: 65px;
		}
	}
	&__summary {
		flex: 1 1 auto;
		font-size: 1.1rem;
	}
}


/* --------------------------------------------------- */
/* legend */
/* --------------------------------------------------- */
.legend {
	display: flex;
	flex-wrap: wrap;
	
	&__item {
		display: flex;
		align-items: center;
		margin: 0 25px 20px 0;
		
		&__icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;
			margin: 0 12px 0 0;
			border: 1px solid $orange;
			color: $orange;
			font-size: $xxxl;
			@include radius(8);
		}
	}
}



/* --------------------------------------------------- */
/* product-info-icon */
/* --------------------------------------------------- */
.product-info-icon {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin: 0 0 30px;
	
	&__content1 {
		width: 13.3%;
		
		&__item {
			margin: 8px 0 0;
			
			&:first-child {
				margin-top: 0;
			}
		}
	}
	&__content2 {
		display: flex;
		flex-wrap: wrap;
		width: 83.2%;
		
		&__item {
			width: calc((100% - 14px) / 3);
			margin: 8px 0 0 7px;
			
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			&:nth-child(-n+3) {
				margin-top: 0;
			}
			&--unsupport {
				@include opacity(0.2);
			}
		}
	}
}


/* --------------------------------------------------- */
/* tab */
/* --------------------------------------------------- */
.tab,
.tab2 {	
	display: flex;
	overflow-x: scroll;
	margin: 0 -15px;
	padding: 0 8px 15px;
	-webkit-overflow-scrolling: touch;
	
	&__item {
		display: flex;
		/*
		flex: 1 1 auto;
		min-width: 80px;
		margin: 0 6px;
		*/
		
		&__body {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 40px;
			background: $white;
			border: 1px solid $borderColor;
			color: $textColor;
			line-height: 1.4;
			white-space: nowrap;
			cursor: pointer;
			@include radius(4);
			@include transition;
			
			&::before {
				bottom: -4px;
				content: '';
				border-style: solid;
				border-width: 4px 4px 0;
				border-color: $baseColor transparent transparent;
				opacity: 0;
				@include transition;
				@include centering-elements(true, false); 
			}
		}
		
		&.active {
			opacity: 1;
			
			.tab__item__body,
			.tab2__item__body {
				background: $baseColor;
				border-color: $baseColor;
				color: $white;
				opacity: 1;
				
				&::before {
					opacity: 1;
				}
			}
		}
	}
	
	&--sp-scroll {
		flex-wrap:  nowrap;
		
		.tab__item,
		.tab2__item {
			width: auto;
			min-width: 80px;
			margin: 0 6px;
		}
	}
	
}


/* --------------------------------------------------- */
/* ノーリツソーシャル */
/* social */
/* --------------------------------------------------- */
.social {
	display: flex;
	flex-wrap: wrap;
	
	&__item {
		position: relative;
		width: 50%;
		
		&__link {
			display: block;
		}
		&__thumb {
			position: relative;
			padding-top: 100%;
			background: $white;
			overflow: hidden;
			
			& > * {
				max-width: 100%;
				@include centering-elements(true, true);
			}
		}
		&__icon {
			position: absolute;
			right: 12px;
			bottom: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $white;
			@include circle(35);
			
			& > * {
				width: 18px;
			}
		}
	}
}



/* --------------------------------------------------- */
/* ノーリツNOW */
/* noritz-now */
/* --------------------------------------------------- */
#noritz-now {
	@include font-form;
	
	.u-font-bold {
		font-weight: bold;
		@include font-form;
	}
	.noritz-now__thumb {
		position: relative;
		margin: 0 0 15px;
		padding-top: 61.7%;
		overflow: hidden;
		@include radius(8);
		
		& > * {
			position: absolute;
			top: 0;
			left: 0;			
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			font-family: 'object-fit: cover; object-position: 50% 50%;';
		}
	}
	
	.ellipsis {
		br {
			display: none;
		}
	}
}


/* ============================================================ */
/* TOTO製品の修理受付 */
/* /repair_toto.html */
/* ============================================================ */
#repair_toto {
	.contents-lower {
		display: none;
	}
}
