/* ============================================================ */
/* エコリラキレイ */
/* /special/ecorelakirei/配下共通 */
/* ============================================================ */
.special_ecorelakirei {
	background: #fafafa;


	.ecorelakirei-head {
		height: 70px;
		background: #EF4023;

		.inner {
			max-width: 980px;
			margin: 0 auto;
			position: relative;
		}

		&__nav {
			display: flex;
		}

		&__logo {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 9;
		}
	}


	.ecorelakirei-content {
		display: flex;
		justify-content: center;

		&__inner {
			width: 980px;
		}
	}
}




/* ============================================================ */
/* エコリラキレイ */
/* /special/ecorelakirei/index.html */
/* ============================================================ */
#special_ecorelakirei_index {
	
	.ecorelakirei-content__inner {
		background: $white;

		img {
			display: block;
			margin: 0 auto;
			user-select: none;
		}
	}

	.cp_auchimo_01 {
		&__01 {
			order: 2;
		}
		&__thumb {
			width: 923px;
			margin: 0 auto;
			
			.sound__box {
				.sound__btn {
					cursor: pointer;
					position: relative;
					
					&::after {
						position: absolute;
						bottom: 65px;
						left: 284px;
						content: url(/campaign/img/cp_auchimo/pic_pickup_thumb_icon.png);
					}
				}
			}
		}
	}
	.cp_auchimo_01 {
		&__02 {
			order: 1;
		}
	}
	
	
	.modalbg {
		background: #fff;
		filter: alpha(opacity=90);
		-moz-opacity: 0.90;
		opacity: 0.90;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		display: none;
	}

	.modalcontents {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20;
		width: 940px;
		display: none;
	}

	.modalcontents .modaltxt {
		font-size: 133.33%;
		color: #fff;
		text-align: center;
	}

	.modalcontents .modalmovie {
		margin-top: 20px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		height: 528px;
	}

	.modalcontents .modalclosebtn {
		position: absolute;
		top: -20px;
		right: 0;
	}
	
	@media screen and (max-width: 980px) {
		.modalcontents {
			width: 100%;
			padding: 0 2%;

			.modalclosebtn {
				right: 2%;
			}

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}
	

	/* 既存の CSS を継承 ▼
	 -------------------------------------------------------------------*/

	#ct01 {
		#main_img {
			background: url(/common/img/ecorelakirei/home/main.jpg) 0 0 no-repeat;
			padding-top: 95.91837%;
			opacity: 1;
			position: relative;
		}

		#main_img #onp1 {
            position: absolute;
            left: 620px;
            top: 235px;
        }
        
        #main_img #onp1 img {
            position: absolute;
        }

        #main_img #onp1 #opw1 {
            left: 0;
            top: 0;
        }

        #main_img #onp1 #opw2 {
            left: 100px;
            top: -35px;
        }

        #main_img #onp1 #opw3 {
            left: 152px;
            top: -28px;
        }

        #main_img #onp1 #opw4 {
            left: 220px;
            top: -68px;
        }

        #main_img #onp1 #opw5 {
            left: 285px;
            top: -84px;
        }

		
		#main_img #txt {
            position: absolute;
            left: 620px;
            top: 370px;
        }

        #main_img #txt path {
            fill: #FFF;
            opacity: 0;
        }
		
		#main_img #onp2 {
            position: absolute;
            left: 300px;
            top: 890px;
        }

        #main_img #onp2 img {
            opacity: 0;
            position: absolute;
        }

		.sub-img {
			margin-top: -32px;
			padding-left: 15px;
			position: relative;
		}

		#se-btn {
			width: 46px;
			height: 46px;
			position: absolute;
			top: 242px;
			left: 562px;
			opacity: 0;
			cursor: pointer;
		}

		.txt {
			margin-top: 58px;
			margin-bottom: 60px;
		}

		.txt .title {
			padding-left: 20px;
		}

		.txt .copy {
			margin-top: 55px;
		}
	}
	

	#ct04-2 {
		position: relative;
		height: 542px;

		.support {
			padding-top: 100px;
		}

		.support-btn {
			cursor: pointer;
			position: relative;
			margin: 50px auto 0;
			width: 340px;
			height: 60px;
		}

		.support-btn img {
			margin: 0;
		}

		.support-btn .arw {
			position: absolute;
			left: 280px;
			top: 18px;
			margin: 0;
		}
	}


	#ct06 {
		background-image: url(/common/img/ecorelakirei/home/06_bg.png);
		background-repeat: no-repeat;
		height: 940px;

		ul {
			width: 700px;
			margin: 0 auto;
			padding-top: 125px;
		}

		ul li {
			margin-top: 45px;
			vertical-align: top;
			display: inline-block;
		}

		ul li:nth-child(odd) {
			margin-right: 70px;
		}

		ul li .thumb-txt {
			margin-top: 25px;
		}
	}
	
    .sclIn {
        opacity: 0
    }

	#ct06 .thumb-img {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transition: all 500ms cubic-bezier(0.19, 1, .22, 1) 0ms;
		transition: all 500ms cubic-bezier(0.19, 1, .22, 1) 0ms;
		opacity: 1;
		-ms-transform: scale(1, 1);
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}

	#ct06 .thumb-img:hover {
		opacity: .85;
		-ms-transform: scale(1.08, 1.08);
		-webkit-transform: scale(1.08, 1.08);
		transform: scale(1.08, 1.08);
	}

	#modal-close {
		opacity: 1;
		-webkit-transition: opacity 500ms cubic-bezier(0.215, .61, .355, 1) 0ms;
		transition: opacity 500ms cubic-bezier(0.215, .61, .355, 1) 0ms;
	}

	#modal-close:hover {
		opacity: .7;
	}

	#intro {
		position: relative;
		top: 70px;
		width: 980px;
		height: 740px;
		margin: 0 auto;
		background-color: #ef4023;
		opacity: 0;

		img {
			display: block;
			margin: 0 auto;
			user-select: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			-khtml-user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
		}

		.onp-icon {
			padding-top: 50px;
			margin-bottom: 50px;
		}

		#sound-select {
			width: 410px;
			margin: 80px auto 0;
			padding-bottom: 80px;
		}

		#sound-select img {
			display: inline-block;
			margin: 0 20px;
			cursor: pointer;
		}

		#intro-copy {
			margin: 0 auto;
			width: 200px;
		}

		#introOnp {
			position: absolute;
			z−index: 1;
			left: 600px;
			top: 340px;
		}

		#introOnp img {
			position: absolute;
		}

		#introOnp #iopw1 {
			left: 0;
			top: 0;
		}

		#introOnp #iopw2 {
			left: 100px;
			top: -35px;
		}

		#introOnp #iopw3 {
			left: 152px;
			top: -28px;
		}

		#introOnp #iopw4 {
			left: 220px;
			top: -68px;
		}

		#introOnp #iopw5 {
			left: 285px;
			top: -84px;
		}
	}

	.btn-off {
		pointer-events: none;
	}

	#download {
		position: relative;
		background: url(/common/img/ecorelakirei/home/download/bg.png) 0  0 no-repeat;
		height: 823px;

		 .title {
			padding-right: 25px;
			padding-top: 115px;
		}

		 #down-ui {
			width: 560px;
			margin: 60px auto 0;
		}

		 #down-ui .down-btn {
			display: inline-block;
			position: relative;
			z-index: 3;
		}

		 #down-ui .down-btn:after {
			content: "";
			display: block;
			width: 296px;
			height: 296px;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			background: #f8afa3;
			margin-left: -22px;
			margin-top: -22px;
			z-index: -1;
			opacity: 0;
			transform: scale(0.85, .85);
			transition: all 500ms cubic-bezier(0.19, 1, .22, 1) 0ms;
		}

		 #down-ui .down-btn:before {
			content: "";
			display: block;
			width: 430px;
			height: 430px;
			top: 0;
			left: 0;
			border-radius: 50%;
			position: absolute;
			background: #f8afa3;
			margin-left: -89px;
			margin-top: -89px;
			z-index: -1;
			opacity: 1;
			transform: scale(0.55, .55);
			transition: opacity 0ms cubic-bezier(0.39, .575, .565, 1) 0ms;
		}

		 #down-ui .down-btn:hover:after {
			transform: scale(1, 1);
			opacity: 1;
		}

		 #down-ui .down-btn:hover:before {
			transform: scale(0.9, .9);
			opacity: 0;
			transition: all 1500ms cubic-bezier(0.23, 1, .32, 1) 0ms;
		}

		 #down-ui #iphone-down {
			margin-right: 50px;
		}

		 #down-onp {
			position: relative;
		}

		 #down-onp .onp {
			position: absolute;
		}

		 #down-onp #d-onp1 {
			left: 52px;
			top: 130px;
		}

		 #down-onp #d-onp2 {
			left: 70px;
			top: 316px;
		}

		 #down-onp #d-onp3 {
			left: 98px;
			top: 220px;
		}

		 #down-onp #d-onp4 {
			left: 170px;
			top: 38px;
		}

		 #down-onp #d-onp5 {
			left: 300px;
			top: 85px;
		}

		 #down-onp #d-onp6 {
			left: 392px;
			top: 218px;
		}

		 #down-onp #d-onp7 {
			left: 614px;
			top: 53px;
		}

		 #down-onp #d-onp8 {
			left: 655px;
			top: 235px;
		}

		 #down-onp #d-onp9 {
			left: 746px;
			top: 74px;
		}

		 #down-onp #d-onp10 {
			left: 895px;
			top: 88px;
		}

		 #down-onp #d-onp11 {
			left: 888px;
			top: 193px;
		}

		 #down-onp #d-onp12 {
			left: 890px;
			top: 300px;
		}
	}



	/* -------------------------------------------------------------------
	既存の CSS を継承 ここまで▲ */


	.contents {
		margin: 0 auto -35px;
	}

	.ecorelakirei-link-list {
		width: 700px;
		margin: 0 auto;
		padding-top: 125px;
		justify-content: space-between;

		&__item {
			text-align: center;
			margin-bottom: 30px;
			flex: 0 0 311px;
		}
	}
	
	#movie-area {
		position: relative;
		background-image: url(/common/img/ecorelakirei/home/cm/bg3.png), url(/common/img/ecorelakirei/home/cm/bg4.png);
		background-repeat: no-repeat, no-repeat;
		background-position: 0 0, 0 1703px;
		background-color: #ef4023;
		width: 980px;
		height: 1780px;
		padding: 230px 0 0;

		img {
			display: block;
			margin: 0 auto;
		}
	}

	#wanted-area {
		width: 980px;
		padding: 100px 0;
		text-align: center;
	}

}





/* ============================================================ */
/* エコリラキレイ */
/* /special/ecorelakirei/about.html */
/* ============================================================ */

#special_ecorelakirei_about {

	.overlay {
		width: 100%;
		margin-top: 70px;
		top: 190px;
		position: absolute;
		color: #f4858d;
	}

	#overlay_g .overlaycontent,
	#overlay_c .overlaycontent {
		min-height: 1140px;
		width: 1200px;
		text-align: center;
		margin: auto;
		top: 0;
		position: relative;
		background-color: #fafafa;
	}

	#overlay_g {
		color: #646464;
	}

	#overlay_g .g_img {
		margin-top: 30px;
		margin-bottom: 35px;
	}

	#overlay_g .close, #overlay_c .close {
		position: absolute;
		top: 51px;
		left: 897px;
		cursor: pointer;
	}

	.carousel {
		width: 596px;
		height: 596px;
		overflow: hidden;
		position: relative;
		margin: 0 auto 25px;
	}

	.book {
		width: 727px;
		overflow: hidden;
		position: relative;
		padding-top: 30px;
		margin: 0 auto;
	}

	.carousel li {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		opacity: 0;
	}

	.arrow {
		display: block;
		width: 25px;
		height: 49px;
		overflow: hidden;
		position: absolute;
		top: 275px;
	}

	.arrow_l {
		left: 0;
		height: 49px;
		background: url(/common/img/ecorelakirei/about/arrow_l.png) no-repeat left bottom;
	}

	.arrow_r {
		right: 0;
		background: url(/common/img/ecorelakirei/about/arrow_r.png) no-repeat left bottom;
	}

	.arrow:hover {
		background-position: left top;
	}

	.book_nav {
		width: 204px;
		overflow: hidden;
		margin: 0 auto;
	}

	.book_nav li {
		width: 8px;
		height: 8px;
		display: block;
		float: left;
		overflow: hidden;
		background: url(/common/img/ecorelakirei/about/nav_carousel.png) no-repeat left top;
		margin-right: 20px;
	}

	.book_nav li.is_current {
		background-position: -28px top;
	}

	.book_nav li.lastchild {
		margin-right: 0;
	}
}




/* ============================================================ */
/* エコリラキレイ */
/* /special/ecorelakirei/project.html */
/* ============================================================ */
#special_ecorelakirei_project {

	.ecorelakirei-project-list {
		&__item {

			&__col2 {
				display: flex;
				align-items: center;
				padding-left: 30px;
				box-sizing: border-box;
			}

		}
	}

	.modal-ecorelakirei-project {
		position: absolute;
		top: 0;
		left: 0;
		background: url(/common/img/ecorelakirei/project/rice/bg.jpg);
		width: 100%;
		min-width: 940px;
		min-height: 100%;
		display: none;
		opacity: 0;
		filter: alpha(opacity=0);
		z-index: 10;

		.modal_bg {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 10;
		}

		.modal_inner {
			width: 940px;
			margin: 0 auto;
			overflow: hidden;
			position: relative;
			z-index: 99;
		}

		.btn_close {
			float: right;
		}

		.title {
			text-align: center;
			margin-bottom: 40px;
			clear: both;
		}

		.equation {
			background: #fdb344;
			padding: 67px 0 80px 135px;
			position: relative;
		}

		.equation-with-tab {
			&__tab {

			}

			&__content {
				background: #fdb344;
				padding: 45px;
				width: 100%;
				box-sizing: border-box;
			}
		}

		.txt1 {
			position: absolute;
			top: 97px;
			left: 530px;
		}

		.intro {
			width: 100%;
			box-sizing: border-box;
			padding: 30px;
			background: $white;
			border-radius: 20px;
			display: flex;
			justify-content: space-between;
			line-height: 1.6;
		}

		.btn_pagetop {
			position: absolute;
			bottom: 25px;
			right: 50px;
			display: block;
			width: 105px;
			height: 12px;
			overflow: hidden;
			background: url(/common/img/ecorelakirei/project/rice/btn_pagetop.png) no-repeat left top;
		}

		.btn_pagetop:hover {
			background-position: left bottom;
		}
	}
}