@charset "utf-8";

$baseColor: #e8380d;
$textColor: #404544;
$borderColor: #d2d2d2;

$white: #fff;
$purple: #ba77af;
$orange: #ef7355;
$blue: #01299b;

$word: #2c3580;
$excel: #02723b;
$ppt: #dc5b26;

$forestoffset: #7fc116;


@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.6) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}


@mixin font-normal {
	font-family: 'TBUDゴシック R', 'TBUDGothic R';
}

@mixin font-bold {
	font-family: 'TBUDゴシック B', 'TBUDGothic B';
}

@mixin font-form {
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif" !important;
}




/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

