@charset "utf-8";
/* ============================================================ */
/* アフターサポート */
/* /aftersupport/ */
/* ============================================================ */



/* ============================================================ */
/* 修理のご依頼 */
/* /aftersupport/repair/ */
/* ============================================================ */
#aftersupport_repair_index {
	.tab {
		&__item {
			display: flex;
			margin-left: 1%;
			margin-right: 1%;
			width: 31%;

			&__body {
				flex-direction: column;
				height: 90px;
				text-align: center;
			}
			&__icon {
				margin: 0 0 5px;
				font-size: $xxxl;
				line-height: 1;
			}
		}
	}
}

/* ============================================================ */
/* アフターサポート　追加 */
/* /aftersupport/ */
/* ============================================================ */
.bg-lightgray {
	background-color: #EEE !important;
}
.manual_search_item {
	padding: 20px 30px;
	@media screen and (max-width: 767px) {
		padding: 20px 10px;
	}
	&_inner{
		display: flex;
	}
	.-align {
		display: flex;
		align-items: center;
		.bar-width {
			width: 100%;
			margin-bottom: 10px;
			@media (max-width: 1100px) and (min-width: 768px) {
				width: 84%;
			}

			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}
	}

	.search_bar {
		position: relative;

		& input[type="submit"]{
		  cursor: pointer;
		  font-family: 'Font Awesome 5 Free';
		  font-size: 1.3em;
		  border: none;
		  background: none;
		  color: #e8380d;
		  position: absolute;
		  width: 2.5em;
		  height: 2.5em;
		  right: 0;
		  top: -4px;
		  outline : none;

			@media screen and (max-width: 767px) {
				top: -3px;
			}

			@media (max-width: 1100px) and (min-width: 768px) {
				top: -2px;
			}
		}
	}
	.manual_search {
		border: 1px solid #D2D2D2;
		border-radius: 20px;
		padding: 0 20px;
		width: 100%;

		@media screen and (max-width: 767px) {
			border-radius: 25px;
		}
	}

	input::-ms-clear {
		visibility: hidden;
	}
}
