@charset "utf-8";
/* ============================================================ */
/* あ、うちもノーリツキャンペーンLP */
/* /campaign/cp_auchimo.html */
/* ============================================================ */
#index_cp_auchimo {
  .contents {
    background-image: url(/campaign/img/cp_auchimo/bg_cp_auchimo.png);
    padding: 0;
  }
  .cp_auchimo_main {
    width: 100%;
    margin: 0 auto;
	position: relative;
	z-index: 1;
    img {
    }
  }
  .cp_auchimo_main__01 {
	position: relative;
	background: #fff;
	  padding-bottom: 50px;
  }

  .cp_auchimo_main__02 {
	  position: relative;
	  background: #ffef00;
	  padding: 40px 0 0;
	  .btn_click {
		position: absolute;
		bottom: -32%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 10;
	  }
	  
	&::before {
		position: absolute;
		left: 0;
		right: 0;
		top: -42px;
		z-index: 1;
		content: '';
		height: 42px;
		background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_cm_first.png) repeat-x;
	}
	&::after {
		position: absolute;
		left: 0;
		right: 0;
		bottom: -100px;
		z-index: -1;
		content: '';
		height: 100px;
		background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_cm_last.png) bottom center no-repeat;
		background-size: 100% 100%;
	}

  }
  .cp_auchimo_scroll {
    width: 1400px;
    position: absolute;
    top: 70%;
    left: 0;
    z-index: 2;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1400px) {
    .cp_auchimo_scroll {
      transform:scale(0.6);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    .cp_auchimo_scroll {
      transform:scale(0.5);
      top: 65%;
    }
  }
	
.cm {
	margin-bottom: 200px;
	padding: 180px 0 0;
	margin-top: -60px;
    z-index: 0;
	
	&::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -100px;
      z-index: 1;
      content: '';
      height: 100px;
      background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_r_last.png) bottom center no-repeat;
      background-size: 100% 100%;
    }

}
  
  .yellow {
    background-color: #fff000;
    position: relative;
    margin: 100px 0;
	  
	.cp_auchimo_01 {
		&__01 {
			order: 2;
		}
		&__thumb {
			.sound__box {
				.sound__btn {
					cursor: pointer;
					position: relative;
					
					&::after {
						position: absolute;
						bottom: 65px;
						left: 284px;
						content: url(/campaign/img/cp_auchimo/pic_pickup_thumb_icon.png);
					}
					@media screen and (min-width: 811px) and (max-width: 850px) {
						&::after {
							left: 264px;
						}
					}
					@media screen and (min-width: 767.5px) and (max-width: 810px) {
						&::after {
							left: 244px;
						}
					}
				}
			}
		}
	}
	.cp_auchimo_01 {
		&__02 {
			order: 1;
		}
	  }
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      top: -100px;
      z-index: 1;
      content: '';
      height: 100px;
      background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_y_first.png) bottom center no-repeat;
      background-size: 100% 100%;
    }
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -100px;
      z-index: 1;
      content: '';
      height: 100px;
      background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_y_last.png) bottom center no-repeat;
      background-size: 100% 100%;
    }
  }
  .red {
    /*background: #ec1b24;*/
    background: #e4380d;
    position: relative;
  }
  .red--01 {
    top: -100px;
    padding: 200px 0 0;
    margin: 0 0 100px;
  }
  .red--02 {
    position: relative;
    top: 36px;
    padding: 50px 0;
    margin: 150px 0 0;
  }
  .red--03 {
    top: -100px;
    padding: 200px 0 0;
    margin: 0 0 50px;
  }
  .red--01::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100px;
    content: '';
    height: 100px;
    background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_tokuten_last.png) bottom center no-repeat;
    background-size: 100% 100%;
  }
  .red--02::after {
    position: absolute;
    left: 0;
    right: 0;
    top: -100px;
    content: '';
    height: 100px;
    background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_last.png) bottom center no-repeat;
    background-size: 100% 100%;
  }
  .red--03::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100px;
    content: '';
    height: 100px;
    background: url(/campaign/img/cp_auchimo/bg_cp_auchimo_tokuten_last.png) bottom center no-repeat;
    background-size: 100% 100%;
  }
  .pagetop {
    position: relative;
    z-index: 1;
  }
	.modalbg {
		background: #fff;
		filter: alpha(opacity=90);
		-moz-opacity: 0.90;
		opacity: 0.90;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		display: none;
	}

	.modalcontents {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20;
		width: 940px;
		display: none;
	}

	.modalcontents .modaltxt {
		font-size: 133.33%;
		color: #fff;
		text-align: center;
	}

	.modalcontents .modalmovie {
		margin-top: 20px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		-webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
		height: 528px;
	}

	.modalcontents .modalclosebtn {
		position: absolute;
		top: -20px;
		right: 0;
	}
	
	@media screen and (max-width: 980px) {
		.modalcontents {
			width: 100%;
			padding: 0 2%;

			.modalclosebtn {
				right: 2%;
			}

			iframe {
				width: 100%;
				height: 100%;
			}
		}
	}

	.red_text {
		color: #e8380d;
	}

	.gray_text {
		color: #222;
	}
	
	.ttl03 {
		font-weight: bold;
		border-left: 0;
		padding: 0;
	}
	.ttl04 {
		font-weight: bold;
		margin: 0 0 25px;
		padding: 0 0 0 20px;
		border-left: 3px solid #e8380d;
		color: #333;
		font-size: 2.4rem;
		line-height: 1.4;
		font-family: 'TBUDゴシック B', 'TBUDGothic B';
	}
	/*.campaign-pos {
		position: absolute;
		bottom: -70px;
		left: 50%;
		-webkit-transform: translate(-50%,-50%);
		-moz-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
		-o-transform: translate(-50%,-50%);
		transform: translate(-50%,-50%);
		z-index: 9999;
		width: 100%;
	}*/
	.campaign-pos01 {
		.campaign-pos01-btn {
			display: block;
			margin-top: 36px;
		}
	}
}

/* 2カラム 左をやや広く
----------------------------------------------------------- */

.column2 {
	&> .cp_auchimo_a_01 {
		/*width: 60%;
		margin-left: 0;
		margin-right: 0;*/
		width: calc((120% - 41px) / 2 );
	}

	&> .cp_auchimo_a_02 {
		width: 100%;
		.cp_auchimo_a_02-position {
			position: absolute;
			top: -75px;
			right: 0px;
		}
	}
}
	
@media screen and (max-width: 1024px) {
	.column2 {
		&> .cp_auchimo_a_02 {
			width: 70%;
			.cp_auchimo_a_02-position {
				width: 43%;
				top: -45px;
				right: 0px;
			}
		}
	}
}
