@charset "utf-8";
/* ============================================================ */
/* 製品に関する大切なお知らせ */
/* /info/ */
/* ============================================================ */
.info {
	
	.box {
		.info-danger {
			display: flex;
			margin: -30px -30px 30px;
			border-bottom: 1px solid $baseColor;
		
			&__ttl {
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 150px;
				border-right: 1px solid $baseColor;
				color: $baseColor;
				font-size: 2.8rem;
				letter-spacing: 0.2em;
				@include font-bold
			}
			&__text {
				flex: 1 1 auto;
				padding: 15px 20px;
				border-radius: 0 8px 0 0;
				background: #feead6;
			}
		}	
	}
	
	

}
