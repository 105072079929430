@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	order: -2;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	border-bottom: 1px solid $borderColor;
	
	.inner2 {
		position: relative;
		z-index: 15;
		display: flex;
		align-items: center;
		height: 60px;
		padding: 0 15px;
		background: $white;
	}
	
	.header-logo {
		flex: 0 0 110px;
		margin-right: auto;
	}
	
	.header-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: $baseColor;
		line-height: 1;
		cursor: pointer;
		@include transition;
		
		&.active {
			color: #d2d2d2;
		}
		
		&__icon {
			font-size: $xxxl;
		}
		&__text {
			font-size: $xxxs;
			transform: scale(0.8);
			transform-origin: center center;
		}
	}
	
	.nav {
		position: fixed;
		top: 0; 
		left: 0;
		z-index: -1;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding: 60px 0 0;
		background: $white;
		overflow-y: auto;
		transform: translateY(-100%);
		transition: 0.3s all;
		
		& > * {
			flex: 0 0 auto;
		}
		.gnav-show & {
			z-index: 10;
			opacity: 1;
			transform: translateY(0);
		}
		
		&__search {
			order: 0;
			margin: 0 15px 20px;
			padding: 5px 0 0;
			border-top: 1px solid $borderColor;

			form {
				display: flex;
				align-items: center;
				border-bottom: 2px solid $baseColor;
			}
			input[type="text"] {
				flex: 1 1 auto;
				height: 40px;
				padding: 0;
				border: none;
				background: none;
				appearance: none;
			}

			&__btn {
				flex: 0 0 auto;
				width: 40px;
				height: 40px;
				background: none;
				border: none;
				color: $baseColor;
				font-size: $xxl;
			}
		}
		
		.hnav01 {
			order: 3;
			display: flex;
			background: #f3f3f3;

			&__item {
				width: 50%;
				
				&__link {
					display: flex;
					align-items: center;
					height: 65px;
					padding: 0 15px;
					color: $textColor;
				}
				&:nth-child(2n) {
					.hnav01__item__link {
						padding-left: 25px;
					}
				}
			}
		}
		
		.hnav02 {
			order: 2;
			display: flex;
			
			&__item {
				width: 50%;
				
				&__link {
					display: flex;
					align-items: center;
					height: 60px;
					padding: 0 15px;
					color: $textColor;
					
					&__icon {
						margin: 0 8px 0 0;
						color: #808080;
						font-size: $xxxl;
					}
				}
				
				&:nth-child(2n+1) {
					.hnav02__item__link {
						padding-left: 25px;
					}
				}
			}
		}
		
		.gnav {
			order: 1;
			margin: 0 15px;
			border-bottom: 1px solid $borderColor;
			
			&__item {
				border-top: 1px solid $borderColor;
				
				&__link {
					display: flex;
					align-items: center;
					padding: 20px 10px;
					color: $textColor;
					font-size: $m;
					@include font-bold;
					
					&__text {
						flex: 1 1 auto;
					}
					&.active {
						.icon-plus::before {
							content: "\f068";
						}
					}
				}
			}
			
			.dropdown {
				display: none;
				
				&__ttl {
					border-top: 1px solid $borderColor;
					border-bottom: 1px solid $borderColor;
					
					&__link {
						display: block;
						padding: 20px 25px;
						color: $textColor;
						font-size: $m;
						@include font-bold;
					}
				}
				&__menu {
					border-bottom: 1px solid $borderColor;
					
					&__item {
						display: flex;
						align-items: center;
						width: 100%;
						margin-bottom: 0;
						padding: 20px 12px 20px 25px;
						border-bottom: 1px solid $borderColor;
						color: $textColor;
						font-size: $m;
						line-height: 1.4;
						
						&__text {
							flex: 1 1 auto;
						}
						&:last-child {
							border-bottom: none;
						}
					}
					
					&.sp-column2 {
						margin: 0;
						
						.dropdown__menu__item {
							display: flex;
							width: 50%;
							margin: 0;
							
							&:nth-child(2n+1) {
								border-right: 1px solid $borderColor;
							}
						}
					}
				}
			}
			
		}
		
		
		&__close {
			order: 4;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			background: #7e8987;
			color: $white;
		}
		
		
	}
}