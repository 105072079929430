@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.pagetop {
	display: flex;
	justify-content: flex-end;
	max-width: 1160px;
	margin: 0 auto;
	padding: 0 20px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 190px;
		height: 36px;
		background: #f3f3f3;
		border-radius: 10px 10px 0 0;
		color: $textColor;
		font-size: $xxs;
		@include font-bold;
		
		&:hover {
			color: $baseColor;
		}
	}
}


footer {
	padding: 65px 0;
	background: #f3f3f3;
	
	.fnav {
		max-width: 1160px;
		margin: 0 auto;
		padding: 0 20px;
	}
	
	.fnav01 {
		display: flex;
		margin: 0 0 70px;
		border-right: 1px solid $borderColor;
		font-size: $xxs;
		line-height: 1.4;
		
		&__column {
			display: flex;
			flex-direction: column;
			width: calc(100% / 6);
			
			&__body {
				flex: 1 0 auto;
				padding: 0 5px 0 15px;
				border-left: 1px solid $borderColor;
				
				&:not(:last-child) {
					margin: 0 0 40px;
				}
			}
		}
		&__ttl {
			margin: 0 0 25px;
			font-size: $m;
		}
		&__sns {
			display: flex;
			justify-content: space-between;
			padding-right: 10px;
			
			&__item {
				max-width: 32px;
			}
		}
		
	}
	
	.fnav02 {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0 30px;
		font-size: 1.1rem;
		
		&__item {
			padding: 0 15px;
			border-right: 1px solid $borderColor;
			
			&:first-child {
				border-left: 1px solid $borderColor;
			}
			
			&__link {
				color: $textColor;
			}
		}
	}
	
	.copyright {
		font-size: 1.1rem;
		text-align: center;
	}
}